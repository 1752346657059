<template>
    <div>
        <div v-for="zoneType in zoneTypes" :key="zoneType.key">
            <div v-if="zones[zoneType.key]?.length > 0" class="mb-2 mt-4">
                <div
                    class="flex w-full cursor-pointer items-center bg-gray-200 px-4 py-2 text-sm"
                    @click="zoneType.show = !zoneType.show"
                >
                    <h3 class="flex flex-1 justify-center">
                        {{ zoneType.title }}</h3
                    >
                    <base-chevron-icon
                        :class="{ 'rotate-180': zoneType.show }"
                    />
                </div>
                <div v-auto-animate>
                    <ul class="p-4" v-if="zoneType.show">
                        <li
                            v-for="zone in zones[zoneType.key]"
                            :key="zone.id"
                            class="flex cursor-pointer items-center border-b border-gray-200"
                            @mouseenter="handleZoneOpacity(zone.id, 0.6)"
                            @mouseleave="handleZoneOpacity(zone.id, 0.25)"
                            @click="$emit(
                                'toggle-edit-mode',
                                zone,
                                zoneType.key
                            )"
                        >
                            <div class="flex flex-col">
                                <span class="text-sm font-medium">
                                    {{ zone.name }}</span
                                >
                                <span
                                    class="text-xs"
                                    v-if="
                                        zoneType.key === 'drivingDistanceZones'
                                    "
                                >
                                    Driving distance:
                                    {{ zone.radius }} mi
                                </span>
                                <span class="mb-2 text-xs">
                                    Fee:
                                  <span class="font-mono">
                                    {{ (zone.deliveryFee / 100) | currency }}
                                  </span>
                                    <span v-if="zone.minDeliveryAmount">
                                        Min:
                                      <span class="font-mono">
                                        {{ (zone.minDeliveryAmount / 100) | currency }}
                                      </span>
                                    </span>
                                </span>

                            </div>
                            <div class="flex flex-1 justify-end">
                              <base-button
                                buttonText="Edit"
                                size="sm"
                              />
                            </div>
                        </li></ul
                    >
                </div>
            </div>
        </div>
        <div class="mt-4 flex w-full justify-end p-4">
            <base-button
                @clicked="$emit('toggle-add-mode')"
                buttonText="Add new zone"
                size="sm"
                button-style="action"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeliveryZonesList',
    props: {
        zones: {
            type: Object,
            required: true
        },
        venue: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            zoneTypes: [
                {
                    key: 'drivingDistanceZones',
                    title: 'Driving Distance Zones',
                    show: true
                },
                { key: 'polygonZones', title: 'Polygon Zones', show: true },
                { key: 'circleZones', title: 'Circle Zones', show: true }
            ]
        };
    },
    mounted() {
        if (this.isUKAddress) {
            this.zoneTypes.push({
                key: 'postcodeZones',
                title: 'Postcode Zones',
                show: true
            });
        }
    },
    computed: {
        isUKAddress() {
            return this.venue.address.country === 'GB';
        }
    },
    methods: {
        handleZoneOpacity(id, opacity) {
            this.$emit('change-zone-opacity', id, opacity);
        }
    }
};
</script>
