<template>
    <div>
        <div class="mt-4 flex flex-row justify-between">
            <div class="flex max-w-md flex-col pb-4">
                <p class="mt-1 text-sm leading-5 text-gray-500">
                    Create your {{ tableDescriptor }}s. Use areas to group
                    {{ tableDescriptor }}s together to help your customers find
                    them.
                </p>
            </div>
            <div class="flex pb-4">
                <flat-file-launcher
                    class="mr-3"
                    button-style="block px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    managed
                    title="Table import"
                    :fields="tableImportFields"
                    type="Table"
                    :status="csvImportStatus"
                    @csvImport="submitCsvTable"
                />
                <button
                    v-if="editableTables && editableTables.length"
                    @click="exportTables()"
                    type="button"
                    class="focus:outline-none group -ml-2 mt-2 flex cursor-pointer items-center rounded-sm p-2 text-sm leading-5 text-gray-700 hover:text-gray-800 focus:bg-gray-200 sm:mr-6"
                >
                    <svg
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-600 group-hover:text-gray-700"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                        ></path>
                    </svg>
                    Export
                </button>
            </div>
        </div>
        <div
            class="grid grid-cols-3 overflow-hidden bg-white shadow sm:rounded-md"
        >
            <AreaListContainer
                lockAxis="y"
                v-model="editableTables"
                helperClass="cols-span-1 h-64 overflow-y-scroll"
                :distance="15"
                style="height: 350px"
                @input="getSortedTables"
            >
                <AreaListOption
                    v-show="editableTables && editableTables.length"
                    v-for="(area, index) in editableTables"
                    :key="area.id"
                    :area="area"
                    :index="index"
                    :selectedTableAreaIndex="selectedTableAreaIndex"
                    @selectTableArea="selectTableArea"
                />

                <li>
                    <span
                        @click="showAddTableAreaModal"
                        class="focus:outline-none block cursor-pointer transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50"
                    >
                        <div class="flex items-center px-4 py-3 xl:py-4">
                            <div class="flex w-full flex-1 items-center">
                                <div
                                    class="flex w-full justify-center rounded-md border-2 border-dashed border-gray-300 bg-white px-6 pb-3 pt-2"
                                >
                                    <div class="text-center">
                                        <p class="mt-1 text-sm text-gray-600">
                                            <button
                                                type="button"
                                                class="focus:outline-none font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"
                                            >
                                                Add an area
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </li>
            </AreaListContainer>
            <ul
                class="col-span-1 h-64 overflow-y-scroll"
                style="height: 350px"
                v-if="selectedTableArea !== null"
            >
                <li
                    v-for="table in editableTables[selectedTableAreaIndex]
                        .tables"
                    :key="table.id"
                >
                    <span
                        @click="selectedTable = table"
                        :class="{
                            'bg-gray-50': selectedTable === table
                        }"
                        class="focus:outline-none block cursor-pointer border-b border-r border-gray-100 transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50"
                    >
                        <div class="flex items-center px-2 py-4 xl:px-3">
                            <div class="flex min-w-0 flex-1 items-center">
                                <div
                                    class="min-w-0 flex-1 px-2 md:grid md:grid-cols-2 md:gap-2"
                                >
                                    <div>
                                        <div
                                            class="truncate text-sm font-medium leading-5 text-indigo-600"
                                        >
                                            {{ table.name }}
                                        </div>
                                    </div>
                                    <div class="hidden md:block">
                                        <div
                                            class="ml-1 flex flex-shrink-0 justify-end"
                                        >
                                            <span
                                                class="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800"
                                                v-if="!table.id"
                                            >
                                                Unsaved
                                            </span>
                                            <span
                                                class="inline-flex rounded-full bg-gray-200 px-2 text-xs font-semibold leading-5 text-gray-400"
                                                v-else-if="!table.active"
                                            >
                                                Inactive
                                            </span>
                                            <span
                                                class="inline-flex rounded-full bg-blue-200 px-2 text-xs font-semibold leading-5 text-blue-400"
                                                v-else-if="isSnoozed(table)"
                                            >
                                                Snoozed for
                                                {{
                                                    table.disabledUntil
                                                        | moment(
                                                            'from',
                                                            'now',
                                                            true
                                                        )
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <svg
                                    class="h-5 w-5 text-gray-400"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                        </div>
                    </span>
                </li>
                <li>
                    <span
                        @click="showAddTableModal"
                        class="focus:outline-none block cursor-pointer border-r border-gray-100 transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50"
                    >
                        <div class="flex items-center px-4 py-3">
                            <div class="flex w-full flex-1 items-center">
                                <div
                                    class="flex w-full justify-center rounded-md border-2 border-dashed border-gray-300 bg-white px-6 pb-3 pt-2"
                                >
                                    <div class="text-center">
                                        <p class="mt-1 text-sm text-gray-600">
                                            <button
                                                type="button"
                                                class="focus:outline-none font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"
                                            >
                                                Add a {{ tableDescriptor }}
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </li>
                <li>
                    <div class="mt-3 flex w-full items-center justify-center">
                        <span
                            @click="toggleAreaActive(selectedTableArea)"
                            class="cursor-pointer px-3 py-1 text-sm font-medium"
                            :class="
                                selectedTableArea.active
                                    ? 'text-gray-500 hover:bg-gray-100 hover:text-gray-500'
                                    : 'text-green-500 hover:bg-green-100 hover:text-green-500'
                            "
                        >
                            {{
                                selectedTableArea.active
                                    ? 'Deactivate'
                                    : 'Activate'
                            }}
                        </span>

                        <span
                            @click="showSnoozeModal(selectedTableArea, 'area')"
                            class="cursor-pointer px-3 py-1 text-sm font-medium"
                            :class="
                                !isSnoozed(selectedTableArea)
                                    ? 'text-gray-500 hover:bg-gray-100 hover:text-gray-500'
                                    : 'text-blue-500 hover:bg-blue-100 hover:text-blue-500'
                            "
                        >
                            {{
                                isSnoozed(selectedTableArea)
                                    ? 'Wake up'
                                    : 'Snooze'
                            }}
                        </span>

                        <span
                            @click="showDeleteTableAreaModal(selectedTableArea)"
                            class="cursor-pointer px-3 py-1 text-sm font-medium text-gray-500 hover:bg-red-100 hover:text-red-500"
                            >Delete Area</span
                        >
                    </div>
                </li>
                <li class="text-center">
                    <div
                        class="mt-4 text-center"
                        v-if="selectedTableArea.posId"
                    >
                        <span class="text-xs text-gray-500"
                            >POS ID: {{ selectedTableArea.posId }}</span
                        >
                    </div>
                    <AdminIdTag :id="selectedTableArea.id" />
                </li>
            </ul>
            <div
                class="col-span-1 shadow"
                v-if="selectedTable && selectedTable.id"
            >
                <div class="flex h-full w-full flex-col items-center p-4">
                    <div
                        class="mb-2 flex w-full flex-wrap items-center justify-between self-start"
                    >
                        <div class="flex flex-col">
                            <span
                                class="break-all py-1 text-sm font-medium text-indigo-500"
                            >
                                {{ tableDescriptor }}: {{ selectedTable.name }}
                            </span>
                            <span
                                v-if="selectedTable.batchTimeout"
                                class="py-1 text-sm font-medium text-gray-500"
                            >
                                Batch timeout:
                                {{ selectedTable.batchTimeout }}
                            </span>
                        </div>
                        <div class="flex flex-1 justify-end">
                            <span
                                @click="toggleTabActive(selectedTable)"
                                class="cursor-pointer px-3 py-1 text-sm font-medium"
                                :class="
                                    selectedTable.active
                                        ? 'text-gray-500 hover:bg-gray-100 hover:text-gray-500'
                                        : 'text-green-500 hover:bg-green-100 hover:text-green-500'
                                "
                            >
                                {{
                                    selectedTable.active
                                        ? 'Deactivate'
                                        : 'Activate'
                                }}
                            </span>
                            <span
                                @click="showSnoozeModal(selectedTable, 'table')"
                                class="cursor-pointer px-3 py-1 text-sm font-medium"
                                :class="
                                    !isSnoozed(selectedTable)
                                        ? 'text-gray-500 hover:bg-gray-100 hover:text-gray-500'
                                        : 'text-blue-500 hover:bg-blue-100 hover:text-blue-500'
                                "
                            >
                                {{
                                    isSnoozed(selectedTable)
                                        ? 'Wake up'
                                        : 'Snooze'
                                }}
                            </span>
                            <span
                                @click="showDeleteTableModal(selectedTable)"
                                class="cursor-pointer px-3 py-1 text-sm font-medium text-gray-500 hover:bg-red-100 hover:text-red-500"
                            >
                                Delete
                            </span>
                        </div>
                    </div>
                    <div>
                        <qr-code
                            class="mt-2"
                            :url="qrLink(selectedTable.id)"
                            :fileName="`${tableDescriptor}-${selectedTable.name}-QR`"
                            ref="qr"
                        />
                        <div class="mt-4 flex justify-center">
                            <div class="mr-3">
                                <button
                                    type="button"
                                    @click="
                                        copyToClipboard(
                                            qrLink(selectedTable.id)
                                        )
                                    "
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                >
                                    Copy Link
                                </button>
                            </div>
                            <div
                                v-click-outside="
                                    onClickOutsideSingleDownloadButton
                                "
                                class="relative select-none text-left print:hidden"
                            >
                                <button
                                    type="button"
                                    @click="
                                        singleDownloadActionsShow = !singleDownloadActionsShow
                                    "
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                >
                                    Download QR Code
                                </button>
                                <transition
                                    enter-active-class="transition ease-out duration-100"
                                    enter-class="transform opacity-0 scale-95"
                                    enter-to-class="transform opacity-100 scale-100"
                                    leave-active-class="transition ease-in duration-75"
                                    leave-class="transform opacity-100 scale-100"
                                    leave-to-class="transform opacity-0 scale-95"
                                >
                                    <div
                                        v-if="singleDownloadActionsShow"
                                        class="absolute left-0 bottom-12 z-50 mt-2 w-48 origin-top-right rounded-md shadow-lg"
                                    >
                                        <div
                                            class="overflow-hidden rounded-md bg-white shadow-sm"
                                            role="menu"
                                            aria-orientation="vertical"
                                            aria-labelledby="options-menu"
                                        >
                                            <span
                                                class="focus:outline-none group flex w-full cursor-pointer items-center py-1 px-4 py-2 text-center text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                role="menuitem"
                                                @click="saveQR('svg')"
                                            >
                                                <download-icon
                                                    class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                />

                                                Download as SVG
                                            </span>
                                            <div
                                                class="border-t border-gray-100"
                                            ></div>
                                            <span
                                                class="focus:outline-none group flex w-full cursor-pointer items-center py-1 px-4 py-2 text-center text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                role="menuitem"
                                                @click="saveQR('png')"
                                            >
                                                <download-icon
                                                    class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                />
                                                Download as PNG
                                            </span>
                                        </div>
                                    </div>
                                </transition>
                            </div>
                        </div>
                    </div>
                    <div class="text-left" v-if="selectedTable.posId">
                        <span class="text-xs text-gray-500"
                            >POS ID: {{ selectedTable.posId }}</span
                        >
                    </div>
                    <AdminIdTag :id="selectedTable.id" />
                </div>
            </div>
            <div
                class="col-span-1"
                v-else-if="selectedTable && !selectedTable.id"
            >
                <div class="flex h-full w-full flex-col items-center p-4">
                    <span class="font-semibold text-gray-700"
                        >Save your changes to generate a QR code</span
                    >
                </div>
            </div>
        </div>
        <div>
            <button
                v-if="isStoreKit"
                @click="getDeliverectTables()"
                type="button"
                class="focus:outline-none group -ml-2 mt-2 flex cursor-pointer items-center rounded-sm p-2 text-sm leading-5 text-gray-700 hover:text-gray-800 focus:bg-gray-200 sm:mr-6"
            >
                <svg
                    class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-600 group-hover:text-gray-700"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                    ></path>
                </svg>
                ADMIN: Download EPOS Tables
            </button>
        </div>
        <div class="mt-5 rounded-md border bg-white shadow-sm">
            <div class="px-4 py-5 sm:p-6">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Download QR codes
                </h3>
                <div class="mt-2 sm:flex sm:items-start sm:justify-between">
                    <div class="max-w-xl text-sm leading-5 text-gray-500">
                        <p>
                            Download a zip file containing all your tables' QR
                            codes
                        </p>
                    </div>
                    <div
                        class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center"
                    >
                        <span
                            class="relative inline-flex rounded-md shadow-sm"
                            v-click-outside="onClickOutsideBulkDownloadButton"
                            @click="
                                bulkDownloadActionsShow = !bulkDownloadActionsShow
                            "
                        >
                            <button
                                :disabled="isDownloadQRLoading"
                                type="button"
                                class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                            >
                                Download
                            </button>
                            <transition
                                enter-active-class="transition ease-out duration-100"
                                enter-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                            >
                                <div
                                    v-if="bulkDownloadActionsShow"
                                    class="absolute right-0 bottom-12 z-50 mt-2 w-48 origin-top-right rounded-md shadow-lg"
                                >
                                    <div
                                        class="overflow-hidden rounded-md bg-white shadow-sm"
                                        role="menu"
                                        aria-orientation="vertical"
                                        aria-labelledby="options-menu"
                                    >
                                        <span
                                            class="focus:outline-none group flex w-full cursor-pointer items-center py-1 px-4 py-2 text-center text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                            role="menuitem"
                                            @click="bulkDownloadQRCodes('svg')"
                                        >
                                            <download-icon
                                                class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                            />

                                            Download as SVG
                                        </span>
                                        <div
                                            class="border-t border-gray-100"
                                        ></div>
                                        <span
                                            class="focus:outline-none group flex w-full cursor-pointer items-center py-1 px-4 py-2 text-center text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                            role="menuitem"
                                            @click="bulkDownloadQRCodes('png')"
                                        >
                                            <download-icon
                                                class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                            />
                                            Download as PNG
                                        </span>
                                    </div>
                                </div>
                            </transition>
                        </span>
                        <span
                            v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                            class="relative inline-flex rounded-md shadow-sm"
                            v-click-outside="
                                onClickOutsideBulkDownloadPATButton
                            "
                            @click="
                                bulkDownloadActionsPATShow = !bulkDownloadActionsPATShow
                            "
                        >
                            <button
                                :disabled="isDownloadQRLoading"
                                type="button"
                                class="focus:outline-none ml-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                            >
                                Download PAT
                            </button>
                            <transition
                                enter-active-class="transition ease-out duration-100"
                                enter-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                            >
                                <div
                                    v-if="bulkDownloadActionsPATShow"
                                    class="absolute right-0 bottom-12 z-50 mt-2 w-48 origin-top-right rounded-md shadow-lg"
                                >
                                    <div
                                        class="overflow-hidden rounded-md bg-white shadow-sm"
                                        role="menu"
                                        aria-orientation="vertical"
                                        aria-labelledby="options-menu"
                                    >
                                        <span
                                            class="focus:outline-none group flex w-full cursor-pointer items-center py-1 px-4 py-2 text-center text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                            role="menuitem"
                                            @click="
                                                bulkDownloadQRCodes(
                                                    'svg',
                                                    'table'
                                                )
                                            "
                                        >
                                            <download-icon
                                                class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                            />

                                            Download as SVG
                                        </span>
                                        <div
                                            class="border-t border-gray-100"
                                        ></div>
                                        <span
                                            class="focus:outline-none group flex w-full cursor-pointer items-center py-1 px-4 py-2 text-center text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                            role="menuitem"
                                            @click="
                                                bulkDownloadQRCodes(
                                                    'png',
                                                    'table'
                                                )
                                            "
                                        >
                                            <download-icon
                                                class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                            />
                                            Download as PNG
                                        </span>
                                    </div>
                                </div>
                            </transition>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <snooze-table-modal
            @table-area-snoozed="tableAreaSnoozed"
            @table-snoozed="tableSnoozed"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';
import AreaListContainer from '../menuBuilder/AreaListContainer';
import AreaListOption from '../menuBuilder/AreaListOption';
import { exportToCsv } from '../../helpers/exportToCsv';
import DownloadIcon from '@/components/icons/DownloadIcon';
import QrCode from '@/components/QrCode';
import { FeaturesEnum } from '@/enums';
import SnoozeTableModal from '@/components/modals/SnoozeTableModal';
import AdminIdTag from '@/components/helpers/AdminIdTag';

export default {
    name: 'TableBuilder',
    props: {
        tables: {
            type: Array,
            default: () => []
        },
        tableDescriptor: {
            type: String,
            required: true
        },
        venueId: {
            type: Number,
            required: true
        },
        venueSlug: {
            type: String,
            required: true
        },
        orderType: {
            type: String,
            required: false,
            default: 'opat'
        }
    },
    components: {
        SnoozeTableModal,
        AreaListContainer,
        AreaListOption,
        QrCode,
        DownloadIcon,
        AdminIdTag,
        FlatFileLauncher: () => import('../csv/FlatFileLauncher')
    },
    data() {
        return {
            FeaturesEnum,
            selectedTableArea: null,
            selectedTableAreaIndex: 0,
            selectedTable: null,
            editableTables: this.tables || [],
            csvImportStatus: 'pending',
            tableImportFields: [
                {
                    label: 'Area Name',
                    key: 'areaName',
                    validators: [
                        {
                            validate: 'required'
                        }
                    ],
                    description:
                        "Group your tables into areas. We'll match table areas by their name so keep them consistent."
                },
                {
                    label: 'Table Name',
                    key: 'tableName',
                    description: 'The name or number displayed to customers',
                    validators: [
                        {
                            validate: 'required'
                        }
                    ]
                },
                {
                    label: 'Covers',
                    key: 'covers',
                    description: 'The maximum number of covers on the table'
                },
                {
                    label: 'POS ID',
                    key: 'posId',
                    description:
                        'The internal POS system table ID, required if your POS uses table IDs to route orders'
                }
            ],
            isDownloadQRLoading: false,
            singleDownloadActionsShow: false,
            bulkDownloadActionsShow: false,
            bulkDownloadActionsPATShow: false
        };
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    methods: {
        isSnoozed(item) {
            const until = this.$moment(item.disabledUntil);

            return until.isAfter();
        },
        showSnoozeModal(item, type) {
            this.$modal.show('snooze-table-modal', {
                item,
                type
            });
        },
        tableAreaSnoozed(disabledUntil) {
            this.selectedTableArea.disabledUntil = disabledUntil;
        },
        tableSnoozed(disabledUntil) {
            this.selectedTable.disabledUntil = disabledUntil;
        },
        async showAddTableModal() {
            const { data } = await this.$axios.get(
                `/venues/${this.venueSlug}/private`
            );

            this.$modal.show('add-table-modal', {
                venueId: this.venueId,
                areaId: this.selectedTableArea.id,
                tableDescriptor: this.tableDescriptor,
                venuePosProvider: data.venue.posProvider
            });
        },
        showAddTableAreaModal() {
            this.$modal.show('add-table-area-modal', { venueId: this.venueId });
        },
        showDeleteTableModal(table) {
            this.$modal.show('delete-table-modal', {
                table: table,
                tableDescriptor: this.tableDescriptor,
                tableDeleted: value => {
                    if (value) {
                        this.selectedTable = null;
                    }
                }
            });
        },
        async toggleTabActive(table) {
            this.isLoading = true;

            try {
                await this.$axios.put(`/tables/${table.id}`, {
                    active: !table.active
                });

                table.active = !table.active;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        showDeleteTableAreaModal(tableArea) {
            this.$modal.show('delete-table-area-modal', {
                tableArea
            });
        },
        async toggleAreaActive(tableArea) {
            this.isLoading = true;

            try {
                await this.$axios.patch(`/venues/areas/${tableArea.id}`, {
                    active: !tableArea.active
                });

                tableArea.active = !tableArea.active;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        saveQR(type) {
            this.$refs.qr.download(type);
        },
        onClickOutsideSingleDownloadButton() {
            this.singleDownloadActionsShow = false;
        },
        onClickOutsideBulkDownloadButton() {
            this.bulkDownloadActionsShow = false;
        },
        onClickOutsideBulkDownloadPATButton() {
            this.bulkDownloadActionsPATShow = false;
        },
        qrLink(tableId) {
            const base = `https://${this.publicHostname}/${this.venueSlug}/`;
            const tableComponent =
                this.orderType === 'pat'
                    ? `table/${tableId}`
                    : `menu?tableId=${tableId}`;
            return base + tableComponent;
        },
        exportTables() {
            const resourceUri = `/venues/${this.venueId}/tables/export-to-csv`;

            this.$modal.show('export-modal', {
                resourceName: this.tableDescriptor + 's',
                resourceUri,
                params: null
            });
        },
        selectTableArea(area, index) {
            this.selectedTableArea = area;
            this.selectedTableAreaIndex = index;
        },
        getSortedTables(tables) {
            this.$emit('sortedTables', tables);
        },
        async submitCsvTable(tables) {
            this.isLoading = true;
            this.csvImportStatus = 'pending';

            try {
                await this.$axios.post(
                    `/venues/${this.venueId}/tables/import-csv`,
                    tables
                );

                this.csvImportStatus = 'success';
                this.$notify({
                    group: 'settings',
                    title: 'Tables uploaded successfully'
                });

                this.$emit('table-area-added');
            } catch (e) {
                this.csvImportStatus = 'failed';
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        exportToCsv(csvData, csvTitle, keysForCsv) {
            exportToCsv(csvData, csvTitle, keysForCsv);
        },
        async getDeliverectTables() {
            this.isLoading = true;

            const params = {
                venueId: this.venueId
            };

            try {
                const { data } = await this.$axios.get(
                    `/deliverect/fetch-tables`,
                    {
                        params
                    }
                );

                this.exportToCsv(data, 'Deliverect Table Export');
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async bulkDownloadQRCodes(type, landingPage = 'menu') {
            try {
                this.isDownloadQRLoading = true;
                const {
                    data
                } = await this.$axios.get(
                    `/venues/${this.venueId}/download-qr`,
                    { responseType: 'blob', params: { type, landingPage } }
                );

                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `QRCodes_${this.venueSlug}_${type}.zip`
                );
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isDownloadQRLoading = false;
            }
        },
        async copyToClipboard(url) {
            await navigator.clipboard.writeText(url);
            this.$notify({
                group: 'settings',
                title: 'Copied to clipboard'
            });
        }
    },
    computed: {
        ...mapGetters({
            isStoreKit: 'user/isStoreKit',
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        publicHostname() {
            return process.env.VUE_APP_WHITELABEL_PUBLIC_HOSTNAME;
        }
    },
    watch: {
        tables(tables) {
            this.editableTables = tables;

            if (!this.editableTables.length) {
                this.selectedTableArea = null;
            }
        }
    }
};
</script>
<style scoped>
li {
    list-style: none;
}
</style>
