<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Shipday Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Details</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Enter your Shipday API Key and we'll send delivery
                            and pickup orders to Shipday so you can manage your
                            own delivery driver fleet.
                            <br /><br />You'll need a Shipday account,
                            <a
                                class="text-indigo-600"
                                href="https://dispatch.shipday.com/signUp/JkFpp0ha9c"
                                rel="noopener"
                                target="_blank"
                                >create one here</a
                            >.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0" v-if="!isLoading">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-3 gap-6">
                                <div class="col-span-3">
                                    <base-input-field
                                        label="Shipday API Key"
                                        id="apiKey"
                                        type="text"
                                        v-model.trim="shipday.apiKey"
                                        :errors="$v.shipday.apiKey.$error"
                                        placeholder="eg xxxxxxxxxx.XXXXXXXXXXXXXXXXXXXX"
                                        errorMessage="That API key doesn't look correct,
                                        please double check."
                                    />
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="enabled"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Integration Status</label
                                    >

                                    <div class="mt-2 flex items-start">
                                        <toggle-switch
                                            v-model="shipday.enabled"
                                        />

                                        <p class="pl-4 text-sm text-gray-500"
                                            >{{
                                                shipday.enabled
                                                    ? 'Enabled - sending orders to shipday'
                                                    : 'Disabled - not sending orders to shipday'
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="orderStatus"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Order status</label
                                    >
                                    <select
                                        id="model"
                                        name="model"
                                        v-model.trim="
                                            shipday.orderStatusTrigger
                                        "
                                        class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    >
                                        <option
                                            v-for="orderStatus in orderStatuses"
                                            :key="orderStatus"
                                            :value="orderStatus"
                                        >
                                            {{ orderStatus }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>

        <!--        <FooterHelp label="notices" link="https://storekit.com" />-->
    </div>
</template>

<script>
// import FooterHelp from '../../helpers/FooterHelp';
import { minLength, required } from 'vuelidate/lib/validators';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';

export default {
    name: 'ShipdaySettings',
    metaInfo: {
        title: 'Shipday Integration'
    },
    props: {
        venueId: {
            type: Number,
            required: true
        }
    },
    components: {
        ToggleSwitch
    },
    data() {
        return {
            isLoading: false,
            shipday: {
                enabled: false,
                apiKey: null,
                orderStatusTrigger: 'Accepted'
            },
            orderStatuses: ['Accepted', 'Preparing', 'Out for Delivery']
        };
    },
    validations: {
        shipday: {
            apiKey: {
                required,
                minLength: minLength(31)
            },
            enabled: {
                required
            }
        }
    },
    async created() {
        this.fetchShipdaySettings();
    },
    methods: {
        async fetchShipdaySettings() {
            try {
                this.isLoading = true;

                const { data } = await this.$axios.get(
                    '/venues/' + this.venueId + '/settings/shipday'
                );

                if (data[0].shipday) {
                    this.shipday = {
                        ...data[0].shipday,
                        orderStatusTrigger:
                            data[0].shipday.orderStatusTrigger || 'Accepted'
                    };
                }
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async saveVenue() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.errors = 'ERROR';
                return false;
            }

            try {
                this.isLoading = true;

                await this.$axios.put(
                    '/venues/' + this.venueId + '/settings/shipday',
                    this.shipday
                );

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    error.response.data.message.includes('key_invalid')
                ) {
                    this.$modal.show('custom-error-modal', {
                        text:
                            "Sorry, that Shipday API key doesn't seem to work. Please double check and try again."
                    });
                    this.shipday.enabled = false;
                }

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
