<template>
    <div>
        <div
            class="focus:outline-none block transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50"
        >
            <div class="px-1 py-1 sm:px-3 sm:py-3">
                <div
                    class="flex flex-col items-center justify-between sm:flex-row"
                >
                    <div
                        class="mb-2 flex flex-row items-center truncate text-sm font-medium leading-5 text-indigo-600 sm:mb-0"
                    >
                        <DeliveryIcon
                            v-if="order.fulfillmentMethod === 'Delivery'"
                            color="text-gray-500"
                            size="sm"
                        />
                        <PickupIcon
                            v-else-if="order.fulfillmentMethod === 'Pickup'"
                            color="text-gray-500"
                            size="sm"
                        />
                        <InstoreIcon
                            v-else-if="order.fulfillmentMethod === 'InStore'"
                            color="text-gray-500"
                            size="sm"
                        />
                        <span
                            class="ml-2 font-mono font-bold"
                            v-if="order.code"
                        >
                            {{ order.code }}
                        </span>
                        <span class="ml-2 font-mono font-bold" v-else>
                            #{{ order.id }}
                        </span>
                    </div>
                    <div class="flex flex-shrink-0 items-center sm:ml-2">
                        <status-badge :status="order.orderStatus" :posRetryCount="order.posRetryCount" /> 

                        <div
                            v-if="order.orderStatus === 'Failed'"
                            class="ml-1 text-red-600"
                            v-tooltip="
                                'Something went wrong sending to the POS'
                            "
                        >
                            <base-warning-icon />
                        </div>

                        <div
                            v-if="
                                order.orderStatus === 'Retrying' ||
                                order.orderStatus === 'Syncing'
                            "
                            class="ml-1"
                            v-tooltip="'Sending order to POS'"
                        >
                            <base-refresh-icon :loading="true" />
                        </div>
                    </div>
                </div>
                <venue-name-tag
                    class="-mb-2 mt-1 hidden sm:inline-block bg-gray-50"
                    v-if="
                        selectedVenuesCount !== 1 &&
                        venuesCount > 1 &&
                        order.venue &&
                        order.venue.name
                    "
                    :name="order.venue.adminName || order.venue.name"
                    :truncate-length="25"
                />
                <div class="mt-1 hidden items-end sm:flex sm:justify-between">
                    <div class="sm:flex">
                        <div
                            class="mr-6 flex flex-col text-xs leading-5 text-gray-500"
                        >
                            <div>
                                {{ order.customer.firstName || 'Guest' }}
                                {{ order.customer.lastName ? `${order.customer.lastName.slice(0, 1)}.` : ''}}
                            </div>
                            <span
                                v-if="order.table"
                                class="text-xs"
                                :class="{ 'font-bold': isActiveTable }"
                                >{{ order.table.name }}</span
                            >
                        </div>
                    </div>

                    <div
                        class="mt-2 flex items-center text-xs leading-5 text-gray-500 sm:mt-0"
                    >
                        <span>
                            <time
                                v-if="order.preOrderDateTime"
                                :key="timeKey"
                                :datetime="order.preOrderDateTime"
                                >{{
                                    order.preOrderDateTime
                                        | moment('from', 'now')
                                }}</time
                            >
                            <time
                                v-else
                                :key="timeKey"
                                :datetime="order.created_at"
                                >{{
                                    order.created_at | moment('from', 'now')
                                }}</time
                            >
                            <!--| Expected at:-->
                            <!--<span >{{order.created_at | moment("from", "now") }}</span>-->
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import DeliveryIcon from '../components/common/DeliveryIcon';
import PickupIcon from '../components/common/PickupIcon';
import InstoreIcon from '../components/common/InstoreIcon';
import VenueNameTag from '../components/common/VenueNameTag';
import StatusBadge from '@/components/StatusBadge';

export default {
    name: 'OrderListItem',
    props: {
        order: {
            type: Object,
            required: true,
            default: () => {}
        },
        selectedVenuesCount: {
            type: Number,
            required: true
        },
        venuesCount: {
            type: Number,
            required: true
        },
        tableId: {
            type: String,
            required: false
        }
    },
    components: {
        InstoreIcon,
        DeliveryIcon,
        PickupIcon,
        VenueNameTag,
        StatusBadge
    },
    data() {
        return {
            timeKey: 0,
            interval: null
        };
    },
    created() {
        const oneMinute = 60000;
        this.interval = setInterval(() => {
            this.timeKey++;
        }, oneMinute);
    },
    destroyed() {
        clearInterval(this.timeKey);
    },
    computed: {
        expectedTime() {
            const createdTime = moment(this.order.created_at);
            return createdTime;
        },
        isActiveTable() {
            return this.tableId === this.order.tableId;
        }
    }
};
</script>

<style scoped></style>
