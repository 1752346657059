<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="General" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Details</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Give your store a name and short description.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6 sm:rounded-md">
                            <div class="grid grid-cols-3 gap-6">
                                <div class="col-span-3 sm:col-span-2">
                                    <base-input-field
                                        type="text"
                                        label="Store name"
                                        tooltip="The name is shown on your store"
                                        id="name"
                                        v-model.trim="settings.name"
                                        :errors="$v.settings.name.$error"
                                    />
                                </div>
                                <base-checkbox
                                    v-model="toggleAdminName"
                                    id="adminNameCheckbox"
                                    label="Internal Name"
                                    caption="Use a different name in the dashboard"
                                />
                                <div
                                    v-if="showAdminName"
                                    class="col-span-3 sm:col-span-2"
                                >
                                    <base-input-field
                                        type="text"
                                        label="Store internal name"
                                        tooltip="The name is shown in the dashboard"
                                        id="adminName"
                                        v-model.trim="settings.adminName"
                                        :errors="$v.settings.adminName.$error"
                                    />
                                </div>
                            </div>

                            <div class="mt-6">
                                <label
                                    for="about"
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                >
                                    About
                                </label>
                                <div class="rounded-md shadow-sm">
                                    <textarea
                                        id="about"
                                        rows="3"
                                        v-model.trim="settings.description"
                                        class="form-input mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    ></textarea>
                                </div>
                                <p class="mt-2 text-sm text-gray-500">
                                    (we recommend 150-300 characters).
                                </p>
                            </div>
                            <div class="col-span-6 sm:col-span-6 lg:col-span-3">
                                <div>
                                    <base-input-field
                                        type="text"
                                        label="Currency"
                                        :disabled="true"
                                        tooltip="This value is set when creating your store, please contact support to change the currency."
                                        id="currency"
                                        :value="currencyCodeFormatted"
                                    />
                                </div>
                            </div>

                            <div class="col-span-6 sm:col-span-6 lg:col-span-3">
                                <div>
                                    <label
                                        for="lang"
                                        class="block text-sm font-medium text-gray-700"
                                    >
                                        Language
                                    </label>
                                    <select
                                        v-model.trim="settings.lang"
                                        id="lang"
                                        name="lang"
                                        class="focus:outline-none mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                    >
                                        <option
                                            v-for="lang of langs"
                                            :key="lang.value"
                                            :value="lang.value"
                                        >
                                            {{ lang.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <timezone-selector
                                class="col-span-6 sm:col-span-6 lg:col-span-3"
                                v-model="settings.timezone"
                            />

                            <div
                                class="col-span-6 sm:col-span-6 lg:col-span-3"
                                @click="copyToClipboard"
                            >
                                <base-input-field
                                    label="storekit ID"
                                    :disabled="true"
                                    :value="settings.id"
                                    name="venueId"
                                    tooltip="This is the ID we use to identify your store in storekit, you may be asked by support or an integration partner for this number. (click to copy store ID)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Contacts</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Your customers will use this information to contact
                            you.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="col-span-6 sm:col-span-4">
                                <label
                                    for="email_address"
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                    >Email address</label
                                >
                                <input
                                    v-model.trim="settings.email"
                                    placeholder="hello@example.com"
                                    id="email_address"
                                    type="email"
                                    class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                    :class="{
                                        'border-red-300':
                                            $v.settings.email.$error
                                    }"
                                />
                            </div>

                            <div class="col-span-6 sm:col-span-4">
                                <label
                                    for="phone_number"
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                    >Phone number</label
                                >
                                <input
                                    v-model.trim="settings.phoneNumber"
                                    placeholder="020 1234 5678"
                                    type="tel"
                                    id="phone_number"
                                    class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                />
                            </div>

                            <div class="col-span-6 sm:col-span-4">
                                <label
                                    for="url"
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                    >Website</label
                                >
                                <input
                                    v-model.trim="settings.url"
                                    id="url"
                                    type="url"
                                    placeholder="https://example.com"
                                    class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                />
                            </div>

                            <div class="col-span-6 sm:col-span-4">
                                <label
                                    for="instagramUsername"
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                    >Instagram Username</label
                                >
                                <div class="mt-1 flex rounded-md shadow-sm">
                                    <span
                                        class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm"
                                    >
                                        https://instagram.com/
                                    </span>
                                    <input
                                        v-model="settings.instagramUsername"
                                        type="text"
                                        name="instagramUsername"
                                        id="instagramUsername"
                                        class="form-input block w-full flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        placeholder="myinsta"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Store address
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            This address will show on your store and be used to
                            calculate delivery distances.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-6 gap-6">
                                <div class="col-span-6">
                                    <label
                                        for="street"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Street address</label
                                    >
                                    <input
                                        v-model.trim="settings.address.street"
                                        placeholder="eg 101 High St"
                                        autocomplete="shipping street-address"
                                        id="street"
                                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                        :class="{
                                            'border-red-300':
                                                $v.settings.address.street
                                                    .$error
                                        }"
                                    />
                                </div>

                                <div class="col-span-6">
                                    <label
                                        for="building_number"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Building name / number
                                        <span class="font-normal text-gray-500"
                                            >(Optional)</span
                                        ></label
                                    >
                                    <input
                                        v-model.trim="
                                            settings.address.buildingNumber
                                        "
                                        placeholder="eg 15-17"
                                        id="building_number"
                                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                    />
                                </div>

                                <div
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <label
                                        for="city"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >City</label
                                    >
                                    <input
                                        id="city"
                                        v-model.trim="settings.address.city"
                                        autocomplete="shipping locality"
                                        placeholder="eg London"
                                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                        :class="{
                                            'border-red-300':
                                                $v.settings.address.city.$error
                                        }"
                                    />
                                </div>

                                <div
                                    class="col-span-6 sm:col-span-3 lg:col-span-3"
                                >
                                    <label
                                        for="postal_code"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Post code</label
                                    >
                                    <input
                                        id="postal_code"
                                        v-model.trim="settings.address.postCode"
                                        placeholder="eg SW1A 1AA"
                                        autocomplete="shipping postal-code"
                                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                        :class="{
                                            'border-red-300':
                                                $v.settings.address.postCode
                                                    .$error
                                        }"
                                    />
                                </div>
                            </div>
                            <div class="col-span-6 sm:col-span-6 lg:col-span-3">
                                <div>
                                    <label
                                        for="location"
                                        class="block text-sm font-medium text-gray-700"
                                        >Country</label
                                    >
                                    <select
                                        v-model.trim="settings.address.country"
                                        id="location"
                                        name="location"
                                        class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        :class="{
                                            'border-red-300':
                                                $v.settings.address.country
                                                    .$error
                                        }"
                                    >
                                        <option
                                            v-for="country in countries"
                                            :key="country.code"
                                            :value="country.code"
                                        >
                                            {{ country.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <base-checkbox
                                v-if="
                                    settings.acceptsPickup ||
                                    settings.acceptsDelivery
                                "
                                v-model="settings.showAddress"
                                :tooltip="`Optionally hide your address from customers before they order.`"
                                id="showAddress"
                                label="Show Address"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Marketing
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Let customers sign up for marketing communications
                            at checkout.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="col-span-6 sm:col-span-4">
                                <label
                                    class="mb-2 block text-sm font-medium leading-5 text-gray-700"
                                    >Show a marketing signup option at checkout
                                </label>
                                <toggle-switch
                                    @input="
                                        settings.optInMessage === ''
                                            ? (settings.optInMessage =
                                                  'Save my details to keep up to date on news and special offers')
                                            : (settings.optInMessage = '')
                                    "
                                    :value="optInEnabled"
                                />
                            </div>
                            <div
                                class="col-span-6 sm:col-span-4"
                                :class="{
                                    'select-none opacity-50': !settings.optInMessage
                                }"
                            >
                                <label
                                    class="mb-2 block text-sm font-medium leading-5 text-gray-700"
                                    >Preselect the signup option
                                </label>
                                <toggle-switch
                                    v-model="settings.optInPreselect"
                                />
                            </div>
                            <div
                                class="col-span-6 sm:col-span-4"
                                :class="{
                                    'select-none opacity-50': !settings.optInMessage
                                }"
                            >
                                <label
                                    for="optInMessage"
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                    >Opt-in text</label
                                >
                                <input
                                    type="text"
                                    v-model.trim="settings.optInMessage"
                                    placeholder="Save my details to keep up to date on news and special offers"
                                    id="optInMessage"
                                    class="focus:outline-none form-input mt-1 block w-full resize-none rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                />
                                <p class="mt-2 text-sm text-gray-500">
                                    Tip: keep your opt-in message short and easy
                                    to understand.
                                </p>
                            </div>
                            <div class="col-span-6 sm:col-span-4">
                                <label
                                    class="mb-2 block text-sm font-medium leading-5 text-gray-700"
                                    >Hide this store from Google and other
                                    search engines
                                </label>
                                <toggle-switch v-model="settings.noIndex" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Legal Information
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Tax identifier
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <label
                                for="taxId"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                VAT Number
                            </label>
                            <input
                                type="text"
                                v-model.trim="settings.taxId"
                                placeholder="XXXXX"
                                id="taxId"
                                class="focus:outline-none form-input mt-1 block w-full resize-none rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                :class="{
                                    'border-red-300': $v.settings.taxId.$error
                                }"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Close store
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Prevent customers ordering from this store
                            indefinitely.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div
                        class="border border-dashed border-red-400 bg-red-50 shadow sm:overflow-hidden sm:rounded-md"
                    >
                        <div class="space-y-3 px-4 py-5 sm:p-6">
                            <label
                                for="close"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Store is
                                {{ settings.enabled ? 'open' : 'closed' }}
                            </label>
                            <toggle-switch
                                :value="settings.enabled"
                                @input="toggle()"
                                :confirm="settings.enabled"
                                :confirm-title="'Confirm disabling this store'"
                                :confirm-description="'Closing this store means customers will be unable to place any order. If you\'d like to temporarily stop taking orders, use the snooze orders feature.'"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { email, minLength, required } from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';
import currencyMap from '@/helpers/currencies';
import COUNTRIES from '@/helpers/venueLocationCountries.json';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import TimezoneSelector from '@/components/formComponents/TimezoneSelector';

export default {
    name: 'GeneralSettings',
    props: ['venueId', 'venue'],
    metaInfo: {
        title: 'General Settings'
    },
    data() {
        return {
            isLoading: false,
            settings: { ...this.venue },
            currencies: [
                { label: 'Pounds (£)', value: 'GBP' },
                { label: 'Euro (€)', value: 'EUR' },
                { label: 'US Dollars ($)', value: 'USD' },
                { label: 'Canadian Dollars ($)', value: 'CAD' },
                { label: 'Moroccan dirham (د.م)', value: 'MAD' }
            ],
            countries: COUNTRIES,
            FeaturesEnum,
            toggleAdminName: false,
            langs: [
                {
                    label: 'British English',
                    value: 'en-GB'
                },
                {
                    label: 'American English',
                    value: 'en-US'
                },
                {
                    label: 'French',
                    value: 'fr-FR'
                },
                {
                    label: 'Italian',
                    value: 'it-IT'
                },
                {
                    label: 'Spanish',
                    value: 'es-ES'
                },
                {
                    label: 'German',
                    value: 'de-DE'
                }
            ]
        };
    },
    components: {
        ToggleSwitch,
        TimezoneSelector
    },
    validations: {
        settings: {
            address: {
                buildingNumber: {},
                street: {
                    required,
                    minLength: minLength(4)
                },
                city: {
                    required
                },
                postCode: {
                    required
                },
                country: {
                    required
                }
            },
            email: {
                required,
                email
            },
            name: {
                required,
                minLength: minLength(3)
            },
            adminName: {},
            taxId: {
                minLength: minLength(3)
            }
        }
    },
    methods: {
        ...mapActions({
            updateVenueSettings: 'venues/update'
        }),
        async saveVenue() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.errors = 'ERROR';
                return false;
            }

            try {
                this.isLoading = true;

                const {
                    buildingNumber,
                    street,
                    city,
                    postCode,
                    country,
                    venueId,
                    id
                } = this.settings.address;

                const payload = {
                    id: this.venueId,
                    name: this.settings.name,
                    adminName: this.settings.adminName,
                    description: this.settings.description,
                    email: this.settings.email,
                    phoneNumber: this.settings.phoneNumber,
                    url: this.settings.url,
                    instagramUsername: this.settings.instagramUsername,
                    currencyCode: this.settings.currencyCode,
                    lang: this.settings.lang,
                    timezone: this.settings.timezone,
                    address: {
                        buildingNumber,
                        street,
                        city,
                        postCode,
                        country,
                        id,
                        venueId
                    },
                    showAddress: this.settings.showAddress,
                    optInMessage: this.settings.optInMessage,
                    optInPreselect: this.settings.optInPreselect,
                    taxId: this.settings.taxId,
                    noIndex: this.settings.noIndex
                };

                await this.updateVenueSettings(payload);

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async toggle() {
            if (this.isLoading !== true) {
                this.isLoading = true;
                this.settings.enabled = !this.settings.enabled;

                let payload = {
                    id: this.venueId,
                    enabled: this.settings.enabled
                };

                try {
                    await this.updateVenueSettings(payload);
                } catch (error) {
                    this.enabled = !this.settings.enabled;
                    throw new Error(error);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        async copyToClipboard() {
            await navigator.clipboard.writeText(this.settings.id);
            this.$notify({
                group: 'settings',
                title: `Copied store ID: ${this.settings.id} to clipboard`
            });
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        optInEnabled() {
            return !!this.settings.optInMessage;
        },
        currencyCodeFormatted() {
            const currency = currencyMap(this.settings.currencyCode);

            return `${currency.name} (${currency.ISO})`;
        },
        showAdminName() {
            return this.settings.adminName || this.toggleAdminName;
        }
    },
    mounted() {
        this.toggleAdminName = !!this.settings.adminName;
    }
};
</script>
