<template>
    <div class="mx-auto max-w-2xl px-6 sm:px-12">
        <div
            v-if="isLoading"
            style="z-index: 9999"
            class="fixed left-0 top-0 flex h-screen w-screen items-center justify-center bg-gray-900 opacity-75"
        >
            <div class="flex flex-col items-center">
                <span class="mb-2 text-sm text-white"
                    >Creating your account, it may take a few minutes</span
                >
                <div class="h-6 w-6 spinner">
                    <span></span>
                </div>
            </div>
        </div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'paymentSettings' }"
                    class="mb-2 flex items-center text-sm text-gray-500"
                >
                    <base-back-icon />
                    <span>Payment settings</span>
                </router-link>

                <base-page-title title="storekit payments" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4">
                <div class="mt-5 md:mt-0" v-if="!isLoading">
                    <div
                        v-if="$route.query.newAccount && showNotice"
                        class="mb-8 rounded-md bg-yellow-50 p-4"
                    >
                        <div class="flex">
                            <div class="flex-shrink-0">
                                <svg
                                    class="h-5 w-5 text-yellow-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </div>
                            <div class="ml-3">
                                <div
                                    class="flex items-center justify-between text-yellow-800"
                                >
                                    <h3 class="text-sm font-medium">
                                        Creating new Storekit payment accounts
                                    </h3>
                                    <svg
                                        @click="showNotice = false"
                                        class="h-4 w-4 cursor-pointer"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </div>

                                <div class="mt-2 text-sm text-yellow-700">
                                    <p>
                                        You only need to create a new Storekit
                                        payments account if you need to use a
                                        different legal entity (company). You
                                        can connect multiple stores to the same
                                        account.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="shadow sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="">
                                <label
                                    for="businessType"
                                    class="block text-sm font-medium text-gray-700"
                                >
                                    Business Type
                                </label>
                                <select
                                    id="businessType"
                                    name="businessType"
                                    v-model="form.legalEntity"
                                    class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                >
                                    <option value="Business">
                                        Business (Limited Company, LLP, PLC)
                                    </option>
                                    <option value="Individual">
                                        Individual / Sole Trader
                                    </option>
                                    <option value="SoleProprietorship">
                                        Sole proprietorship
                                    </option>
                                </select>
                            </div>

                            <div v-if="!isIndividual" class="mt-4">
                                <label
                                    for="legalBusiness.title"
                                    class="block text-sm font-medium text-gray-700"
                                >
                                    {{
                                        isIndividual
                                            ? 'Trading Name'
                                            : 'Legal Business Name'
                                    }}
                                </label>
                                <div class="mt-1">
                                    <input
                                        v-if="!isInGB"
                                        v-model="form.legalBusiness.title"
                                        type="text"
                                        id="legalBusiness.title"
                                        name="legalBusiness.title"
                                        class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        :class="{
                                            'border-red-300':
                                                $v.form.legalBusiness.title
                                                    .$error
                                        }"
                                        required
                                    />
                                    <business-name-select
                                        v-else
                                        v-model="form.legalBusiness"
                                        :country="venue.address.country"
                                    />
                                </div>

                                <span
                                    v-if="$v.form.legalBusiness.title.$error"
                                    class="text-xs text-red-600"
                                >
                                    Please enter the business name
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="isIndividual" class="mt-5">
                    <div class="shadow sm:rounded-md">
                        <div class="bg-white px-4 py-5 sm:p-6">
                            <h4 class="text-base font-medium text-gray-700">
                                Personal details
                            </h4>
                            <p class="mt-2 text-sm text-gray-500"
                                >The person associated with your StoreKit
                                Payments account should be one of your
                                business's executives.
                            </p>
                            <div class="mt-4 grid grid-cols-6 gap-6">
                                <div
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <label
                                        for="firstName"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >First Name</label
                                    >
                                    <input
                                        id="firstName"
                                        v-model.trim="form.firstName"
                                        autocomplete=""
                                        type="text"
                                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                        :class="{
                                            'border-red-300':
                                                $v.form.firstName.$error
                                        }"
                                    />
                                    <span
                                        v-if="$v.form.firstName.$error"
                                        class="text-xs text-red-600"
                                    >
                                        Please enter a valid name
                                    </span>
                                </div>

                                <div
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <label
                                        for="lastName"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Last Name</label
                                    >
                                    <input
                                        id="lastName"
                                        v-model.trim="form.lastName"
                                        type="text"
                                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                        :class="{
                                            'border-red-300':
                                                $v.form.lastName.$error
                                        }"
                                    />
                                    <span
                                        v-if="$v.form.lastName.$error"
                                        class="text-xs text-red-600"
                                    >
                                        Please enter a valid name
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isSoleProprietorship" class="mt-5">
                    <div class="shadow sm:rounded-md">
                        <div class="bg-white px-4 py-5 sm:p-6">
                            <h4 class="text-base font-medium text-gray-700">
                                Registered address
                            </h4>
                            <div class="mt-4 grid grid-cols-6 gap-6">
                                <adyen-address
                                    :$v="$v.form"
                                    :address="form.address"
                                    :isIndividual="true"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="border-red my-2 border border-dashed bg-red-200 p-3"
                    v-if="
                        bankAccountDetailsError &&
                        isFeatureAvailable(FeaturesEnum.INTERNAL)
                    "
                >
                    <div
                        class="cursor-pointer text-sm font-bold text-red-800"
                        @click="adminOverrideIncorrectBank"
                        >Force bank account</div
                    >
                </div>

                <div class="mt-5">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="bg-white px-4 py-5 sm:p-6">
                            <h4 class="text-base font-medium text-gray-700"
                                >Terms & Conditions</h4
                            >
                            <p class="mt-2 text-sm text-gray-500"
                                >By using storekit Payments, you agree:
                            </p>
                            <div class="mt-2 flex flex-col">
                                <base-checkbox
                                    id="termsAndConditions"
                                    v-model="termsAccepted"
                                >
                                    <template slot="label">
                                        <p class="block text-sm text-gray-500">
                                            To the
                                            <router-link
                                                class="inline-flex items-center text-indigo-600 underline"
                                                :to="{
                                                    name:
                                                        'storeKitPaymentsTerms'
                                                }"
                                                target="_blank"
                                            >
                                                terms of service
                                                <svg
                                                    class="ml-1 h-4 w-4"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                                                    ></path>
                                                    <path
                                                        d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                                                    ></path>
                                                </svg>
                                            </router-link>
                                        </p>
                                    </template>
                                </base-checkbox>
                                <base-checkbox
                                    id="restrictions"
                                    class="mt-1.5"
                                    v-model="restrictionsAccepted"
                                >
                                    <template slot="label">
                                        <p class="block text-sm text-gray-500">
                                            Your business does not fall under
                                            <a
                                                class="inline-flex items-center text-indigo-600 underline"
                                                href="https://www.adyen.com/legal/list-restricted-prohibited"
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                our restricted services list.
                                                <svg
                                                    class="ml-1 h-4 w-4"
                                                    fill="currentColor"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"
                                                    ></path>
                                                    <path
                                                        d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"
                                                    ></path>
                                                </svg>
                                            </a>
                                        </p>
                                    </template>
                                </base-checkbox>

                                <div class="mt-1">
                                    <span
                                        v-if="
                                            $v.termsAccepted.$error ||
                                            $v.restrictionsAccepted.$error
                                        "
                                        class="text-xs text-red-600"
                                    >
                                        You'll need to accept the terms of
                                        service to create an account.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="mt-1">
            <span v-if="errors" class="text-xs text-red-600">
                Sorry, something went wrong. Please check your answers or
                contact us.
            </span>
        </div>

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span
                class="inline-flex rounded-md shadow-sm"
                @click="createAdyenAccountHolder"
            >
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Next: Complete account setup
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import ably from '@/helpers/ably';
import { mapGetters } from 'vuex';
import { required, minLength } from 'vuelidate/lib/validators';
import BusinessNameSelect from '@/components/venue/settings/adyen/BusinessNameSelect.vue';
import AdyenAddress from '@/components/venue/settings/adyen/AdyenAddress';
import { FeaturesEnum } from '@/enums';

export default {
    name: 'CreateAdyenPlatformAccount',
    metaInfo: {
        title: 'storekit payments Setup'
    },
    props: {
        venue: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    components: {
        BusinessNameSelect,
        AdyenAddress
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        isInGB() {
            return this.venue.address.country !== 'GB';
        },
        isIndividual() {
            return this.form.legalEntity === 'Individual';
        },
        isBusiness() {
            return this.form.legalEntity === 'Business';
        },
        isSoleProprietorship() {
            return this.form.legalEntity === 'SoleProprietorship';
        }
    },
    mounted() {
        if (
            this.$route.query.submitted &&
            this.$route.query.accountHolderCode
        ) {
            this.$router.replace({
                name: 'manageAdyenAccountHolder',
                params: {
                    code: this.$route.query.accountHolderCode
                }
            });
        }
    },
    data() {
        return {
            isLoading: false,
            showNotice: true,
            errors: false,
            form: {
                legalBusiness: { title: '' },
                legalEntity: 'Business',
                countryCode: this.venue.address.country,
                phone: null,
                email: null,
                firstName: null,
                lastName: null,
                bankAccountDetails: {
                    accountNumber: null,
                    sortCode: null,
                    currencyCode: null,
                    countryCode: null,
                    ownerName: null,
                    iban: null,
                    bankBicSwift: null,
                    bankCity: null,
                    bankName: null,
                    ownerCity: null
                },
                shareholders: [
                    {
                        name: {
                            firstName: null,
                            lastName: null
                        },
                        personalData: {
                            dateOfBirth: null
                        },
                        address: {
                            houseNumberOrName: '',
                            street: null,
                            postalCode: '',
                            city: '',
                            country: ''
                        },
                        email: null,
                        fullPhoneNumber: null
                    }
                ],
                venue: {
                    name: this.venue.name,
                    slug: this.$route.params.id,
                    id: this.venue.id
                },
                address: {
                    houseNumberOrName: '',
                    street: null,
                    postalCode: '',
                    city: '',
                    country: ''
                },
                personalData: {
                    dateOfBirth: ''
                }
            },
            termsAccepted: false,
            restrictionsAccepted: false,
            bankAccountDetailsError: false,
            forceBankAccount: false,
            FeaturesEnum,
            adyenHolderCreatedChannel: null
        };
    },
    validations() {
        const v = {
            termsAccepted: {
                checked: value => value === true
            },
            restrictionsAccepted: {
                checked: value => value === true
            }
        };

        if (this.isBusiness) {
            v.form = {
                legalBusiness: {
                    title: {
                        required,
                        minLength: minLength(5)
                    }
                }
            };
        }

        if (this.isIndividual) {
            v.form = {
                firstName: {
                    required,
                    minLength: minLength(2)
                },
                lastName: {
                    required,
                    minLength: minLength(2)
                }
            };
        }

        if (this.isSoleProprietorship) {
            v.form = {
                legalBusiness: {
                    title: {
                        required,
                        minLength: minLength(5)
                    }
                },
                address: {
                    houseNumberOrName: {
                        required
                    },
                    street: {
                        required
                    },
                    postalCode: {
                        required
                    },
                    city: {
                        required
                    },
                    country: {
                        required
                    }
                }
            };
        }

        return v;
    },
    watch: {
        'form.legalEntity'() {
            this.form.legalBusiness = { title: '' };
            this.form.registrationNumber = null;
        }
    },
    beforeDestroy() {
        this.adyenHolderCreatedChannel.detach();
    },
    methods: {
        clearErrors() {
            this.bankAccountDetailsError = false;
            this.errors = false;
        },
        async createAdyenAccountHolder() {
            this.clearErrors();

            try {
                this.$v.$touch();

                if (this.$v.$invalid) {
                    return false;
                }

                const form = {};

                if (this.isBusiness) {
                    form.type = 'organization';
                    form.organization = {
                        legalName: this.form.legalBusiness.title,
                        registeredAddress: {
                            country: this.venue.address.country
                        }
                    };
                }

                if (this.isIndividual) {
                    form.type = 'individual';
                    form.individual = {
                        residentialAddress: {
                            country: this.venue.address.country
                        },
                        name: {
                            firstName: this.form.firstName,
                            lastName: this.form.lastName
                        }
                    };
                }

                if (this.isSoleProprietorship) {
                    form.type = 'soleProprietorship';
                    form.soleProprietorship = {
                        name: this.form.legalBusiness.title,
                        countryOfGoverningLaw: this.venue.address.country,
                        registeredAddress: {
                            city: this.form.address.city,
                            country: this.form.address.country,
                            postalCode: this.form.address.postalCode,
                            street: `${this.form.address.street} ${this.form.address.houseNumberOrName}`
                        }
                    };
                }
                const { data } = await this.$axios.post(
                    `/adyen/platform/create`,
                    form
                );

                this.$router.push({
                    name: 'manageAdyenPlatformAccountHolder',
                    params: {
                        accountHolderId: data
                    }
                });

            } catch (error) {
                this.errors = true;
                this.isLoading = false;
                throw new Error(error);
            }
        },
        pusherListen(accountHolderCode) {
            const channelName = `adyen-holder-created`;
            const event = `${channelName}-event-${accountHolderCode}`;
            this.adyenHolderCreatedChannel = ably.channels.get(channelName);

            this.adyenHolderCreatedChannel.subscribe(event, () => {
                this.isLoading = false;

                this.$router.push({
                    name: 'manageAdyenAccountHolder',
                    params: {
                        code: accountHolderCode
                    }
                });
            });
        },
        adminOverrideIncorrectBank() {
            this.bankAccountDetailsError = false;
            this.forceBankAccount = true;
        }
    }
};
</script>
