<template>
    <div>
        <label
            id="places-search-label"
            class="block text-sm font-medium text-gray-700"
            >Restaurant or store name</label
        >
        <div v-click-outside="onClickOutside" class="relative mt-1">
            <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
                <base-search-icon class="h-4 w-4 text-gray-600" />
            </div>
            <input
                type="text"
                name="places-search"
                id="places-search"
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
                class="block w-full rounded-md border-gray-300 pl-9 pr-12 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                placeholder="Start typing to search for your store"
                aria-describedby="price-currency"
                @input="placeSearch"
                v-model="searchQuery"
            />
            <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4"
            >
                <span
                    class="text-gray-500 sm:text-sm"
                    :class="{ spinner: isLoading }"
                    id="price-currency"
                ></span>
            </div>
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-100"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ul
                    class="focus:outline-none absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm"
                    tabindex="-1"
                    v-show="showOptions && suggestions.length"
                    role="listbox"
                    aria-labelledby="listbox-label"
                >
                    <!--
            Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

            Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
          -->
                    <li
                        v-for="suggestion in suggestions"
                        :key="suggestion.place_id"
                        @click="fetchPlace(suggestion.place_id)"
                        class="relative my-1 cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-gray-50"
                        :id="'listbox-option-' + suggestion.place_id"
                        role="option"
                    >
                        <div class="flex flex-col">
                            <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                            <span
                                class="mb-0 block truncate text-sm font-medium text-gray-700"
                                >{{
                                    suggestion.structured_formatting.main_text
                                }}</span
                            >
                            <!-- Highlighted: "text-indigo-200", Not Highlighted: "text-gray-500" -->
                            <span class="truncate text-xs text-gray-500">{{
                                suggestion.structured_formatting.secondary_text
                            }}</span>
                        </div>
                    </li>
                </ul>
            </transition>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import BaseSearchIcon from '@/components/base/icons/BaseSearchIcon';

export default {
    name: 'GooglePlaceFinder',
    components: { BaseSearchIcon },
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {},
    data() {
        return {
            searchQuery: '',
            suggestions: [],
            isLoading: false,
            selectedPlace: null,
            showOptions: false
        };
    },
    methods: {
        onClickOutside() {
            this.showOptions = false;
        },
        async placeSearch() {
            try {
                if (this.searchQuery.length > 2) {
                    this.isLoading = true;
                    this.showOptions = true;
                    const { data } = await this.$axios.get(`/geo/places`, {
                        params: {
                            input: this.searchQuery
                        }
                    });
                    this.suggestions = data;
                }
            } catch (e) {
                throw new Error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async fetchPlace(placeId) {
            try {
                this.isLoading = true;
                const { data } = await this.$axios.get(`/geo/google-place`, {
                    params: {
                        placeId
                    }
                });

                this.selectedPlace = data;
                this.searchQuery = data.name;
                this.showOptions = false;
                this.$emit('place-selected', data);
            } catch (e) {
                throw new Error(e);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>

<style scoped></style>
