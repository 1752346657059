var render = function render(){var _vm=this,_c=_vm._self._c;return (
        _vm.$route.path !== '/login' &&
        _vm.$route.path !== '/signup' &&
        _vm.$route.path !== '/reset' &&
        _vm.$route.name !== 'liveOrders' &&
        _vm.$route.name !== 'createVenue' &&
        _vm.$route.name !== 'change' &&
        _vm.$route.name !== 'createMenu' &&
        _vm.$route.name !== 'createMenuImport' &&
        _vm.$route.name !== 'menuImportSpreadsheet' &&
        _vm.$route.name !== 'signupWithReferral' &&
        _vm.$route.name !== 'liveTables' &&
        _vm.$route.name !== 'oauthLogin' &&
        _vm.$route.name !== 'oauthConsent' &&
        _vm.$route.name !== 'discountsImport'
    )?_c('div',{staticClass:"md:flex md:flex-shrink-0 print:hidden",on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.sidebarOpen = false}}},[(_vm.$store.state.ui.sidebarOpen)?_c('div',{staticClass:"lg:hidden"},[_c('transition',{attrs:{"enter-active-class":"transition-opacity ease-linear duration-300","enter-class":"opacity-0","enter-to-class":"opacity-100","leave-active-class":"transition-opacity ease-linear duration-300","leave-class":"opacity-100","leave-to-class":"opacity-0"}},[_c('div',{staticClass:"fixed inset-0 z-30 transition-opacity duration-300 ease-linear",on:{"click":function($event){return _vm.$store.commit('ui/UPDATE_SIDEBAR', false)}}},[_c('div',{staticClass:"absolute inset-0 bg-gray-600 opacity-75"})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.state.ui.sidebarOpen),expression:"$store.state.ui.sidebarOpen"}],staticClass:"fixed inset-0 z-40 flex"},[_c('transition',{attrs:{"enter-active-class":"transition ease-in-out duration-300 transform","enter-class":"-translate-x-full","enter-to-class":"translate-x-0","leave-active-class":"transition ease-in-out duration-300 transform","leave-class":"translate-x-0","leave-to-class":"-translate-x-full"}},[_vm._m(0)],1),_c('div',{staticClass:"w-14 flex-shrink-0"})],1)],1):_vm._e(),_vm._m(1)],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('MobileSidebar',{attrs:{"isOgav":_vm.isOgav}})
},function (){var _vm=this,_c=_vm._self._c;return _c('DesktopSidebar',{attrs:{"isOgav":_vm.isOgav}})
}]

export { render, staticRenderFns }