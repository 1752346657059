<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex inline-flex items-center text-sm text-gray-500"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Kitchen CUT Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Details
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Enter your Kitchen CUT API credentials and enable
                            the integration.
                            <br /><br />You'll need a Kitchen CUT account,
                            <a
                                class="text-indigo-600"
                                href="https://www.kitchencut.com/"
                                rel="noopener"
                                target="_blank"
                                >create one here</a
                            >.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0" v-if="!isLoading">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-3 gap-6">
                                <div class="sm:col-span-6">
                                    <base-input-field
                                        v-model="kitchenCut.tenantUrl"
                                        type="url"
                                        prefix="https://"
                                        label="Tenant URL"
                                        id="tenant-url"
                                        placeholder="your-domain.kitchencut.com"
                                        :errors="$v.kitchenCut.tenantUrl.$error"
                                        errorMessage="This field is required"
                                    />
                                </div>

                                <div class="sm:col-span-6">
                                    <base-input-field
                                        v-model="kitchenCut.apiKey"
                                        type="text"
                                        label="API key"
                                        id="api-key"
                                        placeholder="e.g. 1V7e2IkiIK6iuXGvV8U8GzekBKW3OblQnHdKMBFP"
                                        :errors="$v.kitchenCut.apiKey.$error"
                                        errorMessage="This field is required"
                                    />
                                </div>

                                <div
                                    class="sm:col-span-6"
                                    :class="{
                                        'opacity-50': statusSwitchDisabled
                                    }"
                                >
                                    <label
                                        for="enabled"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Integration Status
                                    </label>

                                    <div class="mt-2 flex items-start">
                                        <div class="w-full" v-auto-animate>
                                            <div
                                                class="flex flex-row items-center"
                                                @click="$v.$touch()"
                                            >
                                                <toggle-switch
                                                    v-model="kitchenCut.enabled"
                                                    :isDisabled="
                                                        statusSwitchDisabled
                                                    "
                                                    @toggle-enable="
                                                        toggleEnabled
                                                    "
                                                />
                                                <label
                                                    class="ml-2 text-sm text-gray-500"
                                                >
                                                    {{
                                                        kitchenCut.enabled
                                                            ? 'Enabled - sending orders to Kitchen CUT'
                                                            : 'Disabled - not sending orders to Kitchen CUT'
                                                    }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span
                class="inline-flex rounded-md shadow-sm"
                @click="saveSettings"
            >
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import { mapGetters } from 'vuex';

export default {
    name: 'KitchenCutSettings',
    components: { ToggleSwitch },
    metaInfo: {
        title: 'Kitchen CUT Integration'
    },
    props: {
        venueId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            statusSwitchDisabled: false,
            kitchenCut: {
                tenantUrl: null,
                apiKey: null,
                enabled: false
            }
        };
    },
    validations: {
        kitchenCut: {
            tenantUrl: {
                required
            },
            apiKey: {
                required
            }
        }
    },
    async created() {
        await this.fetchSettings();

        this.kitchenCut.enabled =
            this.venue.integrations.kitchenCut?.enabled || false;
    },
    computed: {
        ...mapGetters({
            venue: 'venues/getVenue'
        })
    },
    methods: {
        async fetchSettings() {
            try {
                this.isLoading = true;

                const { data } = await this.$axios.get('/kitchen-cut/settings');

                this.kitchenCut = { ...this.kitchenCut, ...data };
                this.statusSwitchDisabled = !data.apiKey || !data.tenantUrl;
            } catch (e) {
                throw new Error(`API ${e} `);
            } finally {
                this.isLoading = false;
            }
        },

        async toggleEnabled() {
            try {
                this.kitchenCut.enabled = !this.kitchenCut.enabled;

                await this.$axios.post('/kitchen-cut/toggle', {
                    venueId: this.venueId,
                    enabled: this.kitchenCut.enabled
                });
            } catch (error) {
                this.kitchenCut.enabled = !this.kitchenCut.enabled;
                throw new Error(`API ${error}`);
            }
        },

        async saveSettings() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            try {
                this.isLoading = true;

                await this.$axios.put('/kitchen-cut/settings', {
                    apiKey: this.kitchenCut.apiKey,
                    tenantUrl: this.kitchenCut.tenantUrl
                });

                this.statusSwitchDisabled = false;

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    this.$modal.show('custom-error-modal', {
                        text:
                            'Sorry, there was an error while updating the Kitchen CUT integration, use the live chat button for help.'
                    });

                    this.kitchenCut.enabled = false;
                }

                throw new Error(`API ${error} `);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
