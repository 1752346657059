<template>
    <div id="orkestro-chat-widget-wrapper" class="absolute bottom-4 right-5" />
</template>

<script>
export default {
    name: 'OrkestroChatWidget',
    props: {
        deliveryProviderId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            scriptId: 'orkestro-chat-widget',
            token: null
        };
    },
    async mounted() {
        this.token = await this.generateToken();

        if (!this.token) {
            return;
        }

        if (this.isAlreadyMounted()) {
            this.renderWidget();

            return;
        }

        this.mountScript();
    },
  beforeDestroy() {
    const script = document.getElementById(this.scriptId);
    if (script) {
      script.remove();
    }
  },
  methods: {
        async generateToken() {
            const {
                data: { token }
            } = await this.$axios.get(
                `/orkestro/generate-token/${this.deliveryProviderId}`
            );

            return token;
        },
        renderWidget() {
            /* eslint-disable */
            this.chatWidget = new ChatWidget({
                selector: '#orkestro-chat-widget-wrapper',
                token: this.token,
                orderId: this.deliveryProviderId
            });

            this.chatWidget.render();
            /* eslint-enable */
        },
        mountScript() {
            const script = document.createElement('script');
            script.id = this.scriptId;
            script.type = 'text/javascript';
            script.onload = () => {
                this.renderWidget(this.token, this.deliveryProviderId);
            };
            script.src = `${this.orkestroWidgetUrl}/widgets/chat_widget@latest.js`;
            document.head.appendChild(script);
        },
        isAlreadyMounted() {
            return Boolean(document.getElementById(this.scriptId));
        }
    },
    computed: {
        orkestroWidgetUrl() {
            return process.env.NODE_ENV !== 'production'
                ? 'https://widgets.sandbox.orkestro.io'
                : 'https://widgets.orkestro.io/';
        }
    }
};
</script>
