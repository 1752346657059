export default class FulfillmentMethodsEnum {
    static get IN_STORE() {
        return 'InStore';
    }

    static get DELIVERY() {
        return 'Delivery';
    }

    static get PICKUP() {
        return 'Pickup';
    }

    static get options() {
        return {
            IN_STORE: this.IN_STORE,
            DELIVERY: this.DELIVERY,
            PICKUP: this.PICKUP
        };
    }
}
