<template>
    <div :class="venue.archived ? 'opacity-50' : ''" class="">
      <div class="hidden">
        <AdminIdTag :id="venue.id"></AdminIdTag>
      </div>
        <div class="select-none overflow-hidden group bg-white shadow rounded-sm"
          :class="{ 'rounded-md': displayType === 'grid' }"
        >
            <div class="block relative group" v-if="displayType === 'grid'">
                <div class="flex">
                    <div class="w-full overflow-hidden text-center">
                      <div class="w-full h-full bg-gray-900 opacity-0 group-hover:opacity-50 duration-200 ease-in transition-all absolute"></div>
                        <img
                            :src="staticMapUrl"
                            loading="lazy"
                            @load="mapLoad"
                            @error="mapError"
                        />
                        <div
                            v-show="!mapLoaded"
                            class="w-full spinner"
                            style="height: 100px"
                        ></div>
                    </div>
                </div>
              <div class="absolute flex justify-between pr-4 sm:pr-0 sm:w-auto w-full duration-500 ease-in transition-all sm:opacity-0 group-hover:opacity-100 top-2 left-2">
                <base-button button-text="View store" @clicked="venueRedirect" size="sm" />
                <base-button button-text="QR Code" @clicked="showVenueQrCode(venue.id, venue.slug)" size="sm" class="ml-2">
                  <template #left-icon>
                    <base-qr-code-icon class="-ml-1 mr-2 h-5 w-5" />
                  </template>
                </base-button>
              </div>
            </div>
          <div
              @click="$router.push({ name: 'editVenue', params: { id: venue.slug } })"
              class="flex border-t border-gray-100 cursor-pointer flex-wrap items-center justify-between px-4 py-3 sm:flex-nowrap sm:px-6"
          >
            <div class="pt-1">
              <h3
                  class="flex cursor-pointer items-center font-heading text-base font-medium leading-none text-gray-700"
              >
                {{ venue.adminName || venue.name }}
              </h3>
              <span v-if="venue && venue.address" class="text-xs text-gray-600">
                {{venue.address.buildingNumber}} {{venue.address.street}}, {{venue.address.city}}
              </span>
            </div>
            <div class="flex flex-shrink-0 items-center sm:ml-4">

                <base-button
                    size="sm"
                    buttonText="Settings"
                />
            </div>

          </div>
            <div v-if="displayType === 'grid'" class="border-gray-100 bg-white px-2 pb-2">
                <div class="mt-1 w-full text-sm leading-5 text-gray-900">
                    <ul class="rounded-md">
                        <li
                            class="flex items-center group justify-between py-2 pl-3 pr-4 text-sm leading-5"
                            v-for="(menuItem, index) in venue.menu"
                            :key="menuItem.id"
                        >
                            <div class="flex w-0 flex-1 items-center">
                                <base-menu-item-icon
                                    class="h-4 w-4 flex-shrink-0 text-gray-400"
                                />
                                <span class="ml-2 w-0 flex-1 text-xs truncate">
                                    {{ menuItem.name }}
                                </span>
                                <span
                                    v-if="venue.menu && venue.menu.length > 1 && index === 0"
                                    class="inline-flex items-center rounded-md bg-green-50 px-2 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-300"
                                    >Active</span
                                >
                            </div>
                            <div
                                class="ml-4 text-xs group-hover:opacity-100 transition transition-opacity duration-500 ease-in-out opacity-0 flex flex-shrink-0 items-center text-indigo-600"
                            >
                                <base-edit-icon class="mr-1" />
                                <router-link
                                    :to="{
                                        name: 'editMenu',
                                        params: { id: menuItem.id }
                                    }"
                                    class="font-medium transition duration-150 ease-in-out hover:text-indigo-500"
                                >
                                    Edit Menu
                                </router-link>
                            </div>
                        </li>
                        <li
                            class="flex items-center text-xs justify-between py-2 pl-3 pr-4 leading-5"
                            v-if="venue.menu && venue.menu.length === 0"
                        >
                            <div class="flex w-0 flex-1 items-center">
                                <base-menu-item-icon
                                    class="h-4 w-4 flex-shrink-0 text-gray-400"
                                />
                            </div>
                            <div class="ml-4 flex-shrink-0">
                                <router-link
                                    :to="{
                                        name: 'createMenu',
                                        params: {
                                            venueId: venue.id
                                        }
                                    }"
                                    class="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500"
                                >
                                    Create a menu
                                </router-link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="flex flex-row items-center justify-between border-t border-gray-100 bg-gray-50 px-2 py-2.5 sm:px-4"
            >
                <div>
                  <div>
                    <div
                        v-if="!venue.enabled"
                        class="flex w-full flex-row w-full items-center"
                    >
                        <span class="mr-4 text-sm leading-5 text-gray-500"
                        >Accepting orders</span
                        >
                      <base-small-toggle-switch
                          :value="enabled"
                          @input="toggle()"
                      />
                      <div
                          class="ml-4 h-4 w-4"
                          :class="{ 'text-gray-900 spinner': isLoading }"
                      >
                        <span></span>
                      </div>
                    </div>
                    <div
                        @click="snoozeVenue"
                        class="group flex items-center cursor-pointer w-full py-1 px-2 items-center justify-between rounded-md  hover:bg-gray-100 transition-all duration-300 ease-in-out"
                    >
                        <span
                            v-if="!isSnoozed"
                            class="mr-2 text-xs leading-5 font-medium group-hover:font-bold text-gray-600"
                        >Snooze orders</span
                        >
                        <span
                            v-else
                            title="click to unsnooze"
                            class="mr-2 text-xs font-semibold text-gray-600"
                        >
                            Re-opening
                            {{ venue.disabledUntil | moment('from', 'now') }}
                        </span>
                        <span class="text-indigo-500"
                        ><base-snooze-icon
                            class="w-4 h-4"
                        />
                        </span>
                      </div>
                  </div>
                  </div>
                  <div>
                    <div class="mt-1 text-xs leading-5 text-gray-900 bg-gray-200 rounded-md px-2 ">
                            <span v-if="venue.acceptsPat"
                            >Pay At Table</span
                            >
                      <span v-else-if="venue.visualMenu"
                      >Visual menu</span
                      >
                      <span v-else-if="venue.acceptsInStore"
                      >Order & Pay</span
                      >
                      <span v-else>
                              <span v-if="venue.acceptsPickup"
                              >Pickup</span
                              >
                              <span
                                  v-if="
                                      venue.acceptsPickup &&
                                      venue.acceptsDelivery
                                  "
                              > &
                              </span>
                              <span v-if="venue.acceptsDelivery"
                              >Delivery</span
                              >
                          </span>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';
import AdminIdTag from '@/components/helpers/AdminIdTag';
import BaseButton from "@/components/base/BaseButton.vue";

export default {
    name: 'VenueItem',
    components: {BaseButton, AdminIdTag },
    props: {
        venue: {
            type: Object,
            required: true
        },
        displayType: {
          type: String,
          default: 'grid'
        }
    },
    mounted() {
        this.enabled = this.venue.enabled;
    },
    data() {
        return {
            isLoading: false,
            imageLoading: true,
            enabled: '',
            expanded: false,
            mapLoaded: false,
            FeaturesEnum
        };
    },
    methods: {
        ...mapActions({
            updateVenueSettings: 'venues/update'
        }),
        async toggle() {
            if (this.isLoading !== true) {
                this.isLoading = true;
                this.enabled = !this.enabled;

                let payload = {
                    id: this.venue.id,
                    enabled: this.enabled
                };

                try {
                    await this.updateVenueSettings(payload);
                } catch (error) {
                    this.enabled = !this.enabled;
                    throw new Error(error);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        showVenueQrCode(id, slug) {
            this.$modal.show('created-venue-modal', {
                venueId: id,
                venueSlug: slug,
                newStore: false
            });
        },
        venueRedirect() {
            const venueLink = `https://${this.$store.state.user.profile.partner.orderDomain}/${this.venue.slug}/menu`;

            window.open(venueLink, '_blank');
        },
        snoozeVenue() {
            this.$modal.show('snooze-venue-modal', {
                venueId: this.venue.id,
                disabledUntil: this.venue.disabledUntil
            });
        },
        mapLoad() {
            this.mapLoaded = true;
        },
        mapError() {
           this.mapLoaded = false;
        }
    },
    computed: {
        ...mapGetters({ isFeatureAvailable: 'user/isFeatureAvailable' }),
        uploadCareProxyUrl() {
          return process.env.VUE_APP_UPLOADCARE_PROXY_URL;
        },
        publicHostname() {
            return process.env.VUE_APP_WHITELABEL_PUBLIC_HOSTNAME;
        },
        isSnoozed() {
            const until = this.$moment(this.venue.disabledUntil);
            return until.isAfter();
        },
        staticMapUrl() {
            const baseUrl =
                'https://api.mapbox.com/styles/v1/benlarceysk/ckg6vzzxv4zrw19qhc3xscs5n/static/';
            const long = this.venue.address?.lat;
            const lat = this.venue.address?.lng;
            let customMarkerUrl = encodeURIComponent(
                'https://ucarecdn.com/4d683df2-ac9f-45ef-9bae-d30a12e16ad9/-/format/png/'
            );
            if (this.venue.logo) {
                const logoUrl = `${this.venue.logo}-/format/png/-/resize/50x/-/border_radius/50p/`;
                customMarkerUrl = encodeURIComponent(logoUrl);
            }
            return `${this.uploadCareProxyUrl}/${baseUrl}url-${customMarkerUrl}(${long},${lat})/${long},${lat},15,0,0/600x120@2x?access_token=pk.eyJ1IjoiYmVubGFyY2V5c2siLCJhIjoiY2pycXZnOHp5MDRuMDQ0cHJ4ZWttMGxsYSJ9.MQY0Fnhodssbf7FMG0EiHw&attribution=false&logo=false`;
        }
    }
};
</script>
