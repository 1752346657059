import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import routes from './routes';

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    store.commit('ui/UPDATE_SIDEBAR', false);
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (window.analytics && store.state.user && store.state.user.profile) {
            window.analytics.identify(store.state.user.profile.id, {
                name: store.state.user.profile.name,
                email: store.state.user.profile.email,
                phone: store.state.user.profile.phone,
                user_hash: store.state.user.profile.hash
            });

            window.analytics.group(store.state.user.profile.accounts[0].id, {
                name: store.state.user.profile.accounts[0].name
            });
        }
        if (store.getters['user/isAuthenticated']) {
            if (store.getters['user/currentUser'] === null) {
                store.dispatch('user/CHECK_AUTH');
            } else if (
                store.state.user &&
                store.state.user.profile &&
                store.state.user.profile.id === 1828 &&
                to.name !== 'liveOrders'
            ) {
                next('/live-orders');
            }
            next();
            return;
        }
        next({ name: 'login', query: { redirect: to.fullPath } });
    } else if (
        to.matched.some(record => record.meta.requiresGuest) &&
        store.getters['user/isAuthenticated'] &&
        !to.query.redirect
    ) {
        next('/settings');
    } else {
        next();
    }
});

Vue.use(VueRouter);

export default router;
