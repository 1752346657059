<template>
    <div
        class="customer-card col-span-3 flex flex-col overflow-hidden bg-white shadow sm:rounded-md lg:col-span-1 print:col-span-3 print:flex-row"
    >
        <div class="px-2 py-2 sm:px-5 sm:py-2.5">
            <router-link
                :is="order.customer.email ? 'router-link' : 'span'"
                :to="{
                    name: 'customerDetail',
                    params: { email: order.customer.email }
                }"
                title="View customer profile"
                class="block rounded-md p-1 transition-colors duration-200 ease-in-out hover:bg-gray-50"
            >
                <div class="flex items-center">
                    <div v-if="order.customer.firstName" class="mr-2">
                        <avatar
                            :username="
                                order.customer.firstName +
                                ' ' +
                                order.customer.lastName
                            "
                            :size="32"
                        />
                    </div>
                    <div class="text-sm w-full">
                        <p class="font-heading leading-none text-gray-900">
                            {{ order.customer.firstName || 'Guest' }}
                            {{ order.customer.lastName }}
                        </p>
                        <p
                            v-if="order.customer.email"
                            class="mt-1 text-xs text-gray-600"
                            v-auto-animate
                        >
                            <span
                                v-if="
                                    !customerIsLoading &&
                                    customer.orderCount &&
                                    customer.orderCount > 0
                                "
                            >
                                {{ customerOrderCount }}</span
                            >
                            <span v-else class="inline-flex opacity-70">
                                <span id="blink">.</span>
                                <span id="blink">.</span>
                                <span id="blink">.</span>
                            </span>
                        </p>
                    </div>
                </div>
            </router-link>
        </div>
        <div
            v-if="
                (order.review && order.review.rating) ||
                (customer && customer.reviewsCount > 0)
            "
            class="border-t border-gray-100 px-2 py-2 sm:px-5 sm:py-2.5"
        >
            <div v-if="order.review && order.review.rating">
                <div class="flex flex-row items-center">
                    <h3 class="text-lg font-bold text-gray-700">
                        {{ order.review.rating || 0 }}
                    </h3>
                    <div class="mb-1.5 block pl-2">
                        <star-rating
                            :show-rating="false"
                            :star-size="16"
                            :padding="2"
                            active-color="#fabe00"
                            :increment="0.01"
                            v-model="order.review.rating"
                            :read-only="true"
                        />
                    </div>
                </div>
                <span class="mb-3 block text-xs tracking-wide text-gray-500">
                    Rating of the current order
                </span>
            </div>

            <div
                v-if="
                    customer &&
                    customer.averageReviewsRating &&
                    customer.reviewsCount > 0
                "
            >
                <div class="flex flex-row items-center">
                    <h3 class="text-lg font-bold text-gray-700">
                        {{ customer.averageReviewsRating }}
                    </h3>
                    <div class="mb-1.5 block pl-2">
                        <star-rating
                            :show-rating="false"
                            :star-size="16"
                            :padding="2"
                            active-color="#fabe00"
                            :increment="0.01"
                            v-model="customer.averageReviewsRating"
                            :read-only="true"
                        />
                    </div>
                </div>
                <span
                    v-if="customer.reviewsCount"
                    class="mb-1 block text-xs tracking-wide text-gray-500"
                >
                    Average rating from
                    {{ customer.reviewsCount }} reviews
                </span>
            </div>
        </div>
        <div v-if="order.customer.phone || order.customer.email" class="border-t border-gray-100 px-4 py-3 sm:px-6">
            <div
                class="flex items-center text-sm leading-5 text-gray-400"
                v-if="order.customer.phone"
            >
                <base-phone-icon class="mr-2 h-4 w-4 flex-shrink-0" />
                <a
                    :href="'tel:' + order.customer.phone"
                    class="text-gray-900"
                    target="_blank"
                >
                    {{ order.customer.phone }}
                </a>
            </div>
            <div
                :class="{ 'mt-2' : order.customer.phone }"
                class="flex items-center text-sm leading-5 text-gray-400"
                v-if="order.customer.email"
            >
                <base-email-icon class="mr-2 flex-shrink-0" />
                <a
                    :href="'mailto:' + order.customer.email"
                    target="_blank"
                    class="overflow-hidden text-indigo-600"
                >
                    {{ order.customer.email }}
                </a>
            </div>
        </div>
        <div v-if="order.fulfillmentMethod === 'Delivery'">
            <div
                class="map-container h-64 w-full print:absolute print:left-0 print:w-full"
                v-if="deliveryAddressCoordinates"
            >
                <OrderDeliveryAddressMap
                    :address="order.deliveryAddress"
                    :coordinates="deliveryAddressCoordinates"
                />
            </div>
        </div>
        <div
            v-if="
                order.customer &&
                order.customer.piggy &&
                order.customer.piggy.credits > 0
            "
            class="border-t border-gray-100 bg-gray-50 px-2 py-2 text-sm sm:px-5 sm:py-3"
        >
            <div
                class="flex cursor-pointer justify-between text-gray-800"
                v-if="order.customer.piggy.checkbox"
            >
                <div
                    class="flex items-center justify-between hover:underline"
                    @click="openInPiggy"
                    ><span>
                  Loyalty profile
                  </span>
                    <base-arrow-box-icon class="ml-1 h-4 w-4" />
                </div>
                <span
                    @click="reversePiggyTransaction()"
                  class="cursor-pointer hidden hover:underline"
                >
                    Reverse
                </span>
            </div>
            <div v-if="piggyLoading" class=" flex items-center justify-center h-32">
                <div class="spinner"></div>
            </div>
            <div class="mt-2" v-else>
                <p class="block text-gray-800">
                    Earned: {{ order.customer.piggy.credits }} credits
                </p>
                  <p v-if="customerLoyalty?.current_display_values?.loyalty_balance"
                    class="text-gray-800"
                  >Balance: {{customerLoyalty.current_display_values.loyalty_balance}} </p>
                <p v-if="customerLoyalty?.current_display_values?.loyalty_balance"
                    class="text-gray-800"
                >Transactions: {{customerLoyalty.current_display_values.loyalty_number_of_transactions}} </p>
                  <p v-if="customerLoyalty?.current_display_values?.tier"
                    class="text-gray-800"
                  >Tier: <span class="px-1.5 py-0.5 bg-gray-200 rounded">{{customerLoyalty.current_display_values.tier}} </span></p>
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from 'vue-avatar';
import StarRating from 'vue-star-rating';
import OrderDeliveryAddressMap from '@/components/OrderDeliveryAddressMap';

export default {
    name: 'OrderCustomerCard',
    components: {
        OrderDeliveryAddressMap,
        Avatar,
        StarRating
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            customerIsLoading: false,
            piggyLoading: true,
            customer: {},
            customerLoyalty: {}
        };
    },
    async created() {
        await this.fetchCustomer();
    },
    methods: {
        async fetchCustomer() {
            if (!this.order.customer.email) {
                this.customer = {};
                return;
            }
            this.customerIsLoading = true;

            try {
                const { data } = await this.$axios.get(
                    `/customers/${this.order.customer.email}`
                );

                if (data) {
                    this.customer = data;
                }

                if (this.order.customer.email && this.order.customer?.piggy?.credits > 0) {
                    await this.fetchCustomerLoyalty();
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.customerIsLoading = false;
            }
        },
        async fetchCustomerLoyalty() {
            try {
                this.piggyLoading = true;
                const { data } = await this.$axios.get(
                    `/piggy/contact/${this.order.customer.email}`
                );

                if (data) {
                    this.customerLoyalty = data.data;
                }
            } finally {
                this.piggyLoading = false;
            }
        },
        openInPiggy() {
            window.open(
                `https://business.piggy.eu/contacts/all?keyword=${this.order.customer.email}`,
                '_blank'
            );
        },
        async reversePiggyTransaction() {
            try {
                if (
                    window.confirm(
                        'Are you sure you want to reverse the credits for this transaction'
                    )
                ) {
                    await this.$axios.post(`/piggy/reverse-transaction`, {
                        id: this.order.customer.email,
                        type: 'order',
                        credits: this.order.customer.piggy.credits
                    });

                    this.$emit('loyalty-reversed');
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        }
    },
    computed: {
        deliveryAddressCoordinates() {
            if (!this.order.deliveryAddressCoordinates) {
                return null;
            }

            return [
                this.order.deliveryAddressCoordinates.longitude,
                this.order.deliveryAddressCoordinates.latitude
            ];
        },
        customerOrderCount() {
            const customerOrderCount = this.customer.orderCount;

            return `${customerOrderCount} ${
                customerOrderCount > 1 ? 'Orders' : 'Order'
            }`;
        }
    }
};
</script>

<style scoped>
#blink {
    animation: 1s blink infinite;
}
#blink:nth-child(2) {
    animation-delay: 250ms;
}
#blink:nth-child(3) {
    animation-delay: 500ms;
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
</style>
