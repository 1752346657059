<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex inline-flex items-center text-sm text-gray-500"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Nash Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div v-if="!isLoading && !showSettings" class="py-8">
            <FeaturePanelWithScreenshot
                title="Let us deliver for you, with Nash"
                description="Sell direct for delivery, without having your own drivers."
                :features="nashFeatures"
                image="https://ucarecdn.com/44ba65b5-1476-4e9a-bc26-f2daf69bba7b/-/quality/smart_retina/-/format/auto/"
            >
                <template #cta>
                    <base-button
                        v-if="
                            isFeatureAvailable(FeaturesEnum.SAAS) ||
                            isFeatureAvailable(FeaturesEnum.INTERNAL)
                        "
                        class="mt-2 sm:ml-3 sm:mt-0"
                        @clicked="showSettings = true"
                        buttonText="Enable Nash"
                    />
                    <base-upsell-button
                        v-else
                        upsell-item="nash"
                        buttonText="Upgrade to enable Nash"
                        :disabled="isLoading"
                    ></base-upsell-button>
                </template>
            </FeaturePanelWithScreenshot>
        </div>
        <div v-if="!isLoading && showSettings">
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Details</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Enter your nash API Key and we'll send delivery and
                            pickup orders to nash so you can manage your own
                            delivery driver fleet.
                            <br /><br />You'll need a nash account,
                            <a
                                class="text-indigo-600"
                                href="https://www.usenash.com/"
                                rel="noopener"
                                target="_blank"
                                >create one here</a
                            >.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0" v-if="!isLoading">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-3 gap-6">
                                <div class="col-span-3">
                                    <base-input-field
                                        label="Nash API Key"
                                        id="apiKey"
                                        type="password"
                                        autocomplete="none"
                                        v-model.trim="nash.apiKey"
                                        :errors="$v.nash.apiKey.$error"
                                        placeholder="eg xxxxxxxxxx.XXXXXXXXXXXXXXXXXXXX"
                                        errorMessage="That API key doesn't look correct, please double check."
                                    />
                                </div>
                                <div class="col-span-3">
                                    <base-input-field
                                        label="Nash Org ID"
                                        id="orgId"
                                        type="text"
                                        v-model.trim="nash.orgId"
                                        placeholder=""
                                        errorMessage="That orgId doesn't look correct, please double check."
                                    />
                                </div>
                                <div class="col-span-3">
                                    <base-input-field
                                        label="Nash package requirements"
                                        id="packageRequirements"
                                        type="text"
                                        v-model.trim="nash.packageRequirements"
                                        placeholder=""
                                    />
                                </div>
                                <div class="col-span-3">
                                    <label
                                        for="packageMinimumVehicleSizeOptions"
                                        class="block"
                                        >Nash minimum vehicle size</label
                                    >
                                    <select
                                        name="packageMinimumVehicleSizeOptions"
                                        id="packageMinimumVehicleSizeOptions"
                                        v-model="nash.packageMinimumVehicleSize"
                                        class="rounded-md shadow-sm"
                                    >
                                        <option
                                            v-for="option in packageMinimumVehicleSizeOptions"
                                            :key="option"
                                            :value="option"
                                            >{{ option }}</option
                                        >
                                    </select>
                                </div>
                                <div class="col-span-3">
                                    <base-input-field
                                        label="Nash Options Group ID"
                                        id="optionsGroupId"
                                        type="text"
                                        v-model.trim="nash.optionsGroupId"
                                        placeholder=""
                                    />
                                </div>
                                <div class="col-span-3">
                                    <base-input-field
                                        label="Batch Schedule"
                                        :disabled="
                                            !isFeatureAvailable(
                                                FeaturesEnum.INTERNAL
                                            )
                                        "
                                        id="batchSchedule"
                                        type="text"
                                        v-model.trim="nash.batchSchedule"
                                        placeholder=""
                                    />
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="orderStatus"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Order status</label
                                    >
                                    <select
                                        id="model"
                                        name="model"
                                        v-model.trim="nash.orderStatusTrigger"
                                        class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    >
                                        <option
                                            v-for="orderStatus in orderStatuses"
                                            :key="orderStatus"
                                            :value="orderStatus"
                                        >
                                            {{ orderStatus }}
                                        </option>
                                    </select>
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="pickupInstructions"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Custom Pickup Instructions</label
                                    >
                                    <base-text-area
                                        v-model.trim="nash.pickupInstructions"
                                        :rows="3"
                                        id="pickupInstructions"
                                        class="w-full"
                                        :maxLength="280"
                                    />
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="enabled"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Integration Status</label
                                    >

                                    <div class="mt-2 flex items-start">
                                        <toggle-switch
                                            v-model="nash.enabled"
                                            :is-disabled="
                                                !nash.enabled &&
                                                !isPhoneNumberValid
                                            "
                                        />

                                        <p class="pl-4 text-sm text-gray-500"
                                            >{{
                                                nash.enabled
                                                    ? 'Enabled - sending orders to nash'
                                                    : 'Disabled - not sending orders to nash'
                                            }}
                                        </p>
                                    </div>
                                    <div
                                        v-if="
                                            !nash.enabled && !isPhoneNumberValid
                                        "
                                        class="mt-2 text-xs text-red-600"
                                        >To enable integration, please make sure
                                        a valid phone number is provided in
                                        <router-link
                                            class="cursor-pointer font-medium"
                                            :to="{ name: 'generalSettings' }"
                                            >General Settings.</router-link
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BaseSpacerWithRuler />

            <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
                <span
                    class="inline-flex rounded-md shadow-sm"
                    @click="saveVenue"
                >
                    <button
                        type="button"
                        class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                        :disabled="isLoading"
                    >
                        Save
                    </button>
                </span>
            </div>

            <div
                v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                class="my-6 ml-4 flex flex-shrink-0 justify-end"
            >
                <span
                    class="inline-flex rounded-md shadow-sm"
                    @click="disconnectConnection"
                >
                    <button
                        type="button"
                        class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-red-500 focus:border-red-700 focus:shadow-outline-indigo active:bg-red-700"
                    >
                        Disconnect Auth
                    </button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';
import FeaturePanelWithScreenshot from '@/components/widgets/FeaturePanelWithScreenshot.vue';

export default {
    name: 'NashSettings',
    metaInfo: {
        title: 'Nash Integration'
    },
    props: {
        venueId: {
            type: Number,
            required: true
        }
    },
    components: {
        FeaturePanelWithScreenshot,
        ToggleSwitch
    },
    data() {
        return {
            isLoading: false,
            showSettings: false,
            nash: {
                enabled: false,
                apiKey: null,
                orderStatusTrigger: 'Accepted',
                packageRequirements: null,
                batchSchedule: null,
                orgId: null,
                packageMinimumVehicleSize: null,
                optionsGroupId: null,
                pickupInstructions: null
            },
            packageMinimumVehicleSizeOptions: [
                'None',
                'bikes',
                'motorbike',
                'cargobike',
                'car',
                'sedan',
                'pickup_truck',
                'suv',
                'van',
                'truck'
            ],
            orderStatuses: ['Accepted', 'Preparing', 'Out for Delivery'],
            FeaturesEnum,
            nashFeatures: [
                {
                    name: 'No commission fees',
                    description:
                        'Tap into the best fleets of delivery drivers, reducing costs of third-party delivery by up to 75%.'
                },
                {
                    name: 'Pick your vehicles',
                    description:
                        'Create your own rules for how vehicle types are offered delivery jobs - like cars for orders over £100!'
                },
                {
                    name: 'End-to-end tracking',
                    description:
                        'Keep your customers (and your teams) in the know with order tracking, from acceptance to delivery.'
                }
            ]
        };
    },
    validations: {
        nash: {
            apiKey: {
                required,
                minLength: minLength(31)
            },
            enabled: {
                required
            }
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable',
            venue: 'venues/getVenue'
        }),
        isPhoneNumberValid() {
            const phoneNumber = this.venue.phoneNumber;

            return !!phoneNumber && /^[+]([0-9]){7,16}$/.test(phoneNumber);
        }
    },
    async created() {
        await this.fetchNashSettings();
    },
    methods: {
        async fetchNashSettings() {
            try {
                this.isLoading = true;

                const { data } = await this.$axios.get(
                    '/venues/' + this.venueId + '/settings/nash'
                );

                if (data[0].nash) {
                    this.showSettings = true;
                    this.nash = {
                        ...data[0].nash,
                        orderStatusTrigger:
                            data[0].nash.orderStatusTrigger || 'Accepted'
                    };
                }
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async saveVenue() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.errors = 'ERROR';
                return false;
            }

            try {
                this.isLoading = true;

                await this.$axios.put(
                    '/venues/' + this.venueId + '/settings/nash',
                    this.nash
                );

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    error.response.data.message.includes('key_invalid')
                ) {
                    this.$modal.show('custom-error-modal', {
                        text:
                            "Sorry, that nash API key doesn't seem to work. Please double check and try again."
                    });
                    this.nash.enabled = false;
                }

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async disconnectConnection() {
            try {
                await this.$axios.post(`/nash/${this.venueId}/disconnect`);
                this.$notify({
                    group: 'settings',
                    duration: 1000,
                    text: 'Nash disconnected'
                });
                window.location.reload();
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        }
    }
};
</script>
