<template>
    <div class="grid grid-cols-6 gap-4">
        <div
            class="col-span-6 rounded-md bg-white px-4 py-5 shadow-sm sm:p-5"
            v-for="(day, index) in 7"
            :key="`${day}+${index}+VenueHours`"
        >
            <label
                :for="'hours-' + index"
                class="block text-sm font-medium leading-5 text-gray-700"
            >
                {{ hoursDict[index] }}
            </label>
            <div
                v-for="(singleInterval, intervalIndex) in editableVenueHours"
                v-show="
                    !closedArray[index] &&
                    !isAroundTheClock(editableVenueHours, index)
                "
                :key="`${singleInterval.day}++${intervalIndex}+VenueHours`"
            >
                <div v-if="singleInterval.day === index" class="inline-flex">
                    <vue-timepicker
                        manual-input
                        :minute-interval="1"
                        :id="'hours-' + intervalIndex"
                        :disabled="
                            !editableVenueHours[intervalIndex].active ||
                            isAroundTheClock(editableVenueHours, index)
                        "
                        v-model="editableVenueHours[intervalIndex].openTime"
                        :class="{
                            'opacity-25':
                                !editableVenueHours[intervalIndex].active ||
                                isAroundTheClock(editableVenueHours, index)
                        }"
                        auto-scroll
                        @input="ensureCloseTimeAfterOpenTime(intervalIndex)"
                    >
                    </vue-timepicker>
                    <span class="px-4 py-2 text-gray-600"> to </span>
                    <vue-timepicker
                        manual-input
                        :minute-interval="1"
                        :disabled="
                            !editableVenueHours[intervalIndex].active ||
                            isAroundTheClock(editableVenueHours, index)
                        "
                        :class="{
                            'opacity-25':
                                !editableVenueHours[intervalIndex].active ||
                                isAroundTheClock(editableVenueHours, index)
                        }"
                        v-model="editableVenueHours[intervalIndex].closeTime"
                        :hour-range="getHourRange(intervalIndex)"
                        :minute-range="getMinuteRange(intervalIndex)"
                        auto-scroll
                        :input-class="
                            editableVenueHours[intervalIndex].closeTime ===
                                END_OF_THE_DAY || END_OF_THE_DAY + ':59'
                                ? 'skip-error-style'
                                : ''
                        "
                    >
                    </vue-timepicker>

                    <button
                        v-if="
                            oneDayIntervalsNumber(index, editableVenueHours) > 1
                        "
                        type="button"
                        @click="
                            deleteTimeInterval(
                                intervalIndex,
                                singleInterval.day,
                                editableVenueHours
                            )
                        "
                        class="ml-3"
                        :class="{
                            'opacity-25': !editableVenueHours[intervalIndex]
                                .active
                        }"
                    >
                        <span
                            class="transition-duration-100 cursor-pointer text-red-400 transition hover:text-red-700"
                        >
                            <svg
                                class="h-5 w-5"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
            <div
                class="mb-4 mt-4"
                v-show="
                    !closedArray[index] &&
                    !isAroundTheClock(editableVenueHours, index)
                "
            >
              <base-button
                button-text="Add another time period"
                size="sm"
                @clicked="addTimeInterval(editableVenueHours, index, type)"
              />
            </div>

            <!-- <div
                    class="flex items-center space-x-3"
                    v-show="!closedArray[index]"
                >
                    &lt;!&ndash; On: "bg-indigo-600", Off: "bg-gray-200" &ndash;&gt;
                    <span
                        @click="
                            setAroundTheClock(index)
                        "
                        role="checkbox"
                        tabindex="0"
                        aria-checked="false"
                        class="group relative inline-flex items-center justify-center flex-shrink-0 h-5 w-10 cursor-pointer focus:outline-none"
                    >
                        &lt;!&ndash; On: "bg-indigo-600", Off: "bg-gray-200" &ndash;&gt;
                        <span
                            aria-hidden="true"
                            :class="{
                                'bg-gray-200':
                                    venueHours[
                                        day.day
                                    ] !==
                                    END_OF_THE_DAY,
                                'bg-indigo-600':
                                    closedArray[
                                        day.day
                                    ] === END_OF_THE_DAY
                            }"
                            class="bg-gray-200 absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"
                        ></span>
                        &lt;!&ndash; On: "translate-x-5", Off: "translate-x-0" &ndash;&gt;
                        <span
                            aria-hidden="true"
                            class="translate-x-0 absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform group-focus:shadow-outline group-focus:border-blue-300 transition-transform ease-in-out duration-200"
                        ></span>
                    </span>
                    <span id="toggleLabel">
                        <span
                            class="text-sm leading-5 font-medium text-gray-700"
                            >Open 24 hours
                        </span>
                    </span>
                </div> -->
            <div class="mt-2 flex items-center space-x-3">
                <!-- On: "bg-indigo-600", Off: "bg-gray-200" -->
                <span
                    @click="
                        toggleCloseDay(
                            editableVenueHours,
                            index,
                            closedArray,
                            type
                        )
                    "
                    role="checkbox"
                    tabindex="0"
                    aria-checked="false"
                    class="focus:outline-none group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center"
                >
                    <!-- On: "bg-indigo-600", Off: "bg-gray-200" -->
                    <span
                        aria-hidden="true"
                        :class="{
                            'bg-gray-200': !closedArray[index],
                            'bg-indigo-600': closedArray[index]
                        }"
                        class="absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out"
                    ></span>
                    <!-- On: "translate-x-5", Off: "translate-x-0" -->
                    <span
                        aria-hidden="true"
                        :class="{
                            'translate-x-0': !closedArray[index],
                            'translate-x-5': closedArray[index]
                        }"
                        class="absolute left-0 inline-block h-5 w-5 translate-x-0 transform rounded-full border border-gray-200 bg-white shadow transition-transform duration-200 ease-in-out group-focus:border-blue-300 group-focus:shadow-outline"
                    ></span>
                </span>
                <span :id="'closed-' + day">
                    <span class="text-sm font-medium leading-5 text-gray-700"
                        >Closed
                    </span>
                </span>

                <span class="inline-flex rounded-md shadow-sm">
                    <span
                        @click="
                            toggleAroundTheClock(
                                editableVenueHours,
                                index,
                                type,
                                closedArray
                            )
                        "
                        role="checkbox"
                        tabindex="0"
                        aria-checked="false"
                        class="focus:outline-none group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center"
                    >
                        <span
                            aria-hidden="true"
                            :class="{
                                'bg-gray-200': !isAroundTheClock(
                                    editableVenueHours,
                                    index
                                ),
                                'bg-indigo-600': isAroundTheClock(
                                    editableVenueHours,
                                    index
                                )
                            }"
                            class="absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out"
                        ></span>

                        <span
                            aria-hidden="true"
                            :class="{
                                'translate-x-0': !isAroundTheClock(
                                    editableVenueHours,
                                    index
                                ),
                                'translate-x-5': isAroundTheClock(
                                    editableVenueHours,
                                    index
                                )
                            }"
                            class="absolute left-0 inline-block h-5 w-5 translate-x-0 transform rounded-full border border-gray-200 bg-white shadow transition-transform duration-200 ease-in-out group-focus:border-blue-300 group-focus:shadow-outline"
                        ></span>
                    </span>
                </span>

                <span :id="'24hours-' + day">
                    <span class="text-sm font-medium leading-5 text-gray-700">
                        Open 24 hours
                    </span>
                </span>
            </div>
        </div>

        <div v-if="validationError" class="col-span-6 gap-6">
            <p class="text-sm text-red-400">
                Please add opening times for each day
            </p>
        </div>
    </div>
</template>
<script>
import VueTimepicker from 'vue2-timepicker';
import EditVenueService from '@/services/editVenue.service';
const editVenueService = new EditVenueService();

export default {
    components: {
        VueTimepicker
    },
    props: {
        venueSettings: {
            type: Object,
            default: () => ({}),
            required: false
        },
        venueHours: {
            type: Array,
            default: () => [],
            required: true
        },
        hoursDict: {
            type: Array,
            default: () => [],
            required: true
        },
        END_OF_THE_DAY: {
            type: String,
            default: '',
            required: true
        },
        validationError: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    watch: {
        editableVenueHours: {
            deep: true,
            handler(hours) {
                this.$emit('editedVenueHours', hours);
            }
        }
    },
    data() {
        return {
            closedArray: [false, false, false, false, false, false, false],
            editableVenueHours: this.venueHours,
            type: 'hours'
        };
    },
    created() {
        this.checkForDaysClosed(this.editableVenueHours, this.closedArray);
    },
    methods: {
        toggleCloseDay(array, day, closedArray, type) {
            const {
                resultArray,
                resultClosedArray
            } = editVenueService.toggleCloseDay(array, day, closedArray);

            this.closedArray = resultClosedArray;
            this.editableVenueHours = [...resultArray];

            if (this.isAroundTheClock(this.editableVenueHours, day)) {
                this.toggleAroundTheClock(
                    this.editableVenueHours,
                    day,
                    type,
                    this.closedArray
                );
                this.toggleCloseDay(
                    this.editableVenueHours,
                    day,
                    this.closedArray,
                    type
                );
            }
        },
        toggleAroundTheClock(array, day, type, closedArray) {
            const {
                resultArray,
                resultClosedArray
            } = editVenueService.toggleAroundTheClock(
                array,
                day,
                type,
                closedArray
            );

            this.editableVenueHours = resultArray;
            this.closedArray = resultClosedArray;
        },
        addTimeInterval(array, day, type) {
            this.editableVenueHours = editVenueService.addTimeInterval(
                array,
                day,
                type
            );
        },
        checkForDaysClosed(array, closedArray) {
            this.closedArray = editVenueService.checkForDaysClosed(
                array,
                closedArray
            );
        },
        deleteTimeInterval(index, day, array) {
            this.editableVenueHours = editVenueService.deleteTimeInterval(
                index,
                day,
                array
            );
        },
        oneDayIntervalsNumber(day, array) {
            return editVenueService.oneDayIntervalsNumber(day, array);
        },
        isAroundTheClock(array, index) {
            return editVenueService.isAroundTheClock(array, index);
        },
        getHourRange(index) {
            return [
                [this.editableVenueHours[index].openTime.substring(0, 2), 23]
            ];
        },
        getMinuteRange(index) {
            const openingHour = this.editableVenueHours[
                index
            ].openTime.substring(0, 2);
            const closingHour = this.editableVenueHours[
                index
            ].closeTime.substring(0, 2);

            if (openingHour !== closingHour) {
                return [[0, 59]];
            }

            return [
                [this.editableVenueHours[index].openTime.substring(3, 5), 59]
            ];
        },
        isAfter(openTime, closeTime) {
            const open = this.$moment(openTime, 'HH:mm');
            const close = this.$moment(closeTime, 'HH:mm');

            return open.isAfter(close);
        },
        ensureCloseTimeAfterOpenTime(index) {
            const openTime = this.editableVenueHours[index].openTime;
            const closeTime = this.editableVenueHours[index].closeTime;

            if (this.isAfter(openTime, closeTime)) {
                this.editableVenueHours[
                    index
                ].closeTime = this.editableVenueHours[index].openTime;
            }
        }
    }
};
</script>
