<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex inline-flex items-center text-sm text-gray-500"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Operations" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div v-if="!isOrderAndPay">
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Fulfillment
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Decide which services you'd like to enable and how
                            to receive notifications.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <fieldset>
                                <legend
                                    class="text-base font-medium leading-6 text-gray-900"
                                >
                                    Fulfillment types
                                </legend>
                                <div class="mt-2">
                                    <base-checkbox
                                        v-model="operations.acceptsDelivery"
                                        id="acceptsDelivery"
                                        label="Delivery"
                                        caption="Offer delivery orders (we do not provide drivers or driver management)"
                                    />
                                </div>

                                <div class="mt-2">
                                    <base-checkbox
                                        v-model="operations.acceptsPickup"
                                        id="acceptsPickup"
                                        label="Pickup"
                                        caption="Offer pickup/collection orders"
                                    />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- @TODO sort Organic Market switch PAT on/off -->
        <div
            v-if="
                isFeatureAvailable(FeaturesEnum.INTERNAL) ||
                accountId === 'c7445416-c53b-4bf4-8c81-755d90634be4'
            "
            class="mt-4 md:grid md:grid-cols-3 md:gap-6"
        >
            <div class="mt-5 md:col-span-2 md:col-start-2 md:mt-0">
                <base-button
                    @clicked="togglePatVisual"
                    :buttonText="
                        operations.acceptsPat
                            ? 'Disable bill payments '
                            : 'Enable bill payments'
                    "
                />
            </div>
        </div>

        <!-- @TODO sort incipio switch between service styles -->
        <div
            v-if="
                isFeatureAvailable(FeaturesEnum.INTERNAL) ||
                accountId === '2f8da1f5-7220-4bc9-ae9c-3a184e486932'
            "
            class="mt-4 md:grid md:grid-cols-3 md:gap-6"
        >
            <div class="mt-5 md:col-span-2 md:col-start-2 md:mt-0">
                <base-button
                    @clicked="toggleInVenue"
                    :buttonText="
                        isOrderAndPay
                            ? 'Switch to collection '
                            : 'Switch to table service'
                    "
                />
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Minimum order amount
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Prevent customers from ordering unless they spend a
                            certain amount
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-6 gap-6">
                                <div
                                    v-if="!isOrderAndPay"
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                    >
                                        Delivery orders
                                    </span>
                                    <div
                                        class="relative mt-1 w-full rounded-md shadow-sm"
                                    >
                                        <div
                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                        >
                                            <span
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                {{ currency.symbol }}
                                            </span>
                                        </div>
                                        <currency-input
                                            id="minimumDeliveryAmount"
                                            v-model="
                                                operations.minDeliveryAmount
                                            "
                                            class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                            :class="{
                                                'border-red-300':
                                                    $v.operations
                                                        .minDeliveryAmount
                                                        .$error
                                            }"
                                            placeholder="12.50"
                                            :currency="null"
                                            locale="en-GB"
                                            :value-as-integer="true"
                                            :allow-negative="false"
                                        ></currency-input>
                                    </div>
                                </div>
                                <div
                                    v-if="!isOrderAndPay"
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                        >Pickup orders</span
                                    >
                                    <div
                                        class="relative mt-1 w-full rounded-md shadow-sm"
                                    >
                                        <div
                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                        >
                                            <span
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                {{ currency.symbol }}
                                            </span>
                                        </div>
                                        <currency-input
                                            id="minimumDeliveryAmount"
                                            v-model="operations.minPickupAmount"
                                            class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                            :class="{
                                                'border-red-300':
                                                    $v.operations
                                                        .minPickupAmount.$error
                                            }"
                                            placeholder="0.00"
                                            :currency="null"
                                            locale="en-GB"
                                            :value-as-integer="true"
                                            :allow-negative="false"
                                        ></currency-input>
                                    </div>
                                </div>
                                <div
                                    v-else
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                        >Minimum order amount</span
                                    >
                                    <div
                                        class="relative mt-1 w-full rounded-md shadow-sm"
                                    >
                                        <div
                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                        >
                                            <span
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                {{ currency.symbol }}
                                            </span>
                                        </div>
                                        <currency-input
                                            id="minimumInStoreAmount"
                                            v-model="
                                                operations.minInStoreAmount
                                            "
                                            class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                            :class="{
                                                'border-red-300':
                                                    $v.operations
                                                        .minInStoreAmount.$error
                                            }"
                                            placeholder="0.00"
                                            :currency="null"
                                            locale="en-GB"
                                            :value-as-integer="true"
                                            :allow-negative="false"
                                        ></currency-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Maximum order amount
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Prevent customers from ordering if they spend over a
                            certain amount (Set value to 0 for no upper limit)
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-6 gap-6">
                                <div
                                    v-if="!isOrderAndPay"
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                    >
                                        Delivery orders
                                    </span>
                                    <div
                                        class="relative mt-1 w-full rounded-md shadow-sm"
                                    >
                                        <div
                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                        >
                                            <span
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                {{ currency.symbol }}
                                            </span>
                                        </div>
                                        <currency-input
                                            id="maximumDeliveryAmount"
                                            v-model="
                                                operations.maxDeliveryAmount
                                            "
                                            class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                            :class="{
                                                'border-red-300':
                                                    $v.operations
                                                        .maxDeliveryAmount
                                                        .$error
                                            }"
                                            placeholder="0.00"
                                            :currency="null"
                                            locale="en-GB"
                                            :value-as-integer="true"
                                            :allow-negative="false"
                                        ></currency-input>
                                    </div>
                                </div>
                                <div
                                    v-if="!isOrderAndPay"
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                        >Pickup orders</span
                                    >
                                    <div
                                        class="relative mt-1 w-full rounded-md shadow-sm"
                                    >
                                        <div
                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                        >
                                            <span
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                {{ currency.symbol }}
                                            </span>
                                        </div>
                                        <currency-input
                                            id="maximumPickupAmount"
                                            v-model="operations.maxPickupAmount"
                                            class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                            :class="{
                                                'border-red-300':
                                                    $v.operations
                                                        .maxPickupAmount.$error
                                            }"
                                            placeholder="0.00"
                                            :currency="null"
                                            locale="en-GB"
                                            :value-as-integer="true"
                                            :allow-negative="false"
                                        ></currency-input>
                                    </div>
                                </div>
                                <div
                                    v-else
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                        >Maximum order amount</span
                                    >
                                    <div
                                        class="relative mt-1 w-full rounded-md shadow-sm"
                                    >
                                        <div
                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                        >
                                            <span
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                {{ currency.symbol }}
                                            </span>
                                        </div>
                                        <currency-input
                                            id="maximumInStoreAmount"
                                            v-model="
                                                operations.maxInStoreAmount
                                            "
                                            class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                            :class="{
                                                'border-red-300':
                                                    $v.operations
                                                        .maxInStoreAmount.$error
                                            }"
                                            placeholder="0.00"
                                            :currency="null"
                                            locale="en-GB"
                                            :value-as-integer="true"
                                            :allow-negative="false"
                                        ></currency-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Preparation time
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            The average time it takes to prepare an order for
                            delivery and collection.
                        </p>
                        <p class="mt-2 text-sm text-gray-500">
                            We'll use these values to provide estimated ready
                            time to your customers before they order.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-6 gap-6">
                                <div
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                        >{{
                                            isOrderAndPay
                                                ? 'Average prep time'
                                                : 'Delivery orders'
                                        }}</span
                                    ><div
                                        class="relative mt-1 w-full rounded-md shadow-sm"
                                    >
                                        <currency-input
                                            id="prepTime"
                                            v-model="operations.prepTime"
                                            class="form-input block w-full pr-12 sm:text-sm sm:leading-5"
                                            :class="{
                                                'border-red-300':
                                                    $v.operations.prepTime
                                                        .$error
                                            }"
                                            placeholder="30"
                                            :currency="null"
                                            :precision="0"
                                            locale="en-GB"
                                            :value-as-integer="true"
                                            :allow-negative="false"
                                        ></currency-input>
                                        <div
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                        >
                                            <span
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                Minutes
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="!isOrderAndPay"
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                        >Pickup orders</span
                                    ><div
                                        class="relative mt-1 w-full rounded-md shadow-sm"
                                    >
                                        <currency-input
                                            id="collectionPrepTime"
                                            v-model="
                                                operations.collectionPrepTime
                                            "
                                            class="form-input block w-full pr-12 sm:text-sm sm:leading-5"
                                            :class="{
                                                'border-red-300':
                                                    $v.operations
                                                        .collectionPrepTime
                                                        .$error
                                            }"
                                            placeholder="30"
                                            :currency="null"
                                            :precision="0"
                                            locale="en-GB"
                                            :value-as-integer="true"
                                            :allow-negative="false"
                                        ></currency-input>
                                        <div
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                        >
                                            <span
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                Minutes
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Order Notes
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Allow or prevent customers from entering a custom
                            order note at the checkout.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <large-radio-button
                                v-model="operations.enableOrderNotes"
                                :options="orderNotesOptions"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Item Notes
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Allow or prevent customers from entering custom
                            notes / special requests on each item.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <large-radio-button
                                v-model="operations.enableItemNotes"
                                :options="itemNotesOptions"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            User information
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Select what user information you'd like to collect
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <toggle-switch v-model="customCheckoutSettings" />
                            <span class="text-sm font-medium"
                                >Custom checkout settings</span
                            >
                        </div>
                        <div
                            v-show="customCheckoutSettings"
                            class="space-y-6 bg-white px-4 py-5 sm:p-6"
                        >
                            <div v-if="isOrderAndPay">
                                <div
                                    class="col-span-6 sm:col-span-6 lg:col-span-6"
                                >
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                    >
                                        Phone
                                    </span>
                                    <div class="relative mt-1 rounded-md">
                                        <select
                                            v-model="
                                                inStoreCheckoutSettings.phone
                                            "
                                            class="form-input block w-1/2 shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                        >
                                            <option
                                                v-for="option in phoneOptions"
                                                :key="option"
                                                :value="option"
                                                class="text-gray-500"
                                            >
                                                <span>{{ option }}</span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div
                                    class="col-span-6 sm:col-span-6 lg:col-span-6"
                                >
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                    >
                                        Email
                                    </span>
                                    <div class="relative mt-1 rounded-md">
                                        <select
                                            v-model="
                                                inStoreCheckoutSettings.email
                                            "
                                            class="form-input block w-1/2 shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                        >
                                            <option
                                                v-for="option in emailOptions"
                                                :key="option"
                                                :value="option"
                                                class="text-gray-500"
                                            >
                                                <span>{{ option }}</span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="my-3 flex items-start">
                                    <div class="flex h-5 items-center">
                                        <toggle-switch
                                            v-model="
                                                inStoreCheckoutSettings.firstName
                                            "
                                        />
                                    </div>
                                    <div class="pl-7 text-sm leading-5">
                                        <p class="text-gray-500">
                                            First name
                                        </p>
                                    </div>
                                </div>
                                <div class="my-3 flex items-start">
                                    <div class="flex h-5 items-center">
                                        <toggle-switch
                                            v-model="
                                                inStoreCheckoutSettings.lastName
                                            "
                                        />
                                    </div>
                                    <div class="pl-7 text-sm leading-5">
                                        <p class="text-gray-500"> Last name </p>
                                    </div>
                                </div>
                                <div class="my-3 flex items-start">
                                    <div class="flex h-5 items-center">
                                        <toggle-switch
                                            v-model="
                                                inStoreCheckoutSettings.cutlery
                                            "
                                        />
                                    </div>
                                    <div class="pl-7 text-sm leading-5">
                                        <p class="text-gray-500"> Cutlery </p>
                                    </div>
                                </div>
                                <div class="my-3 flex items-start">
                                    <div class="flex h-5 items-center">
                                        <toggle-switch
                                            v-model="
                                                inStoreCheckoutSettings.companyName
                                            "
                                        />
                                    </div>
                                    <div class="pl-7 text-sm leading-5">
                                        <p class="text-gray-500">
                                            Company name
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="md:grid md:grid-cols-2" v-else>
                                <div>
                                    <legend
                                        class="text-base font-medium text-gray-900"
                                    >
                                        Delivery
                                    </legend>
                                    <div
                                        class="col-span-6 sm:col-span-6 lg:col-span-6"
                                    >
                                        <span
                                            class="text-sm leading-5 text-gray-500"
                                        >
                                            Phone
                                        </span>
                                        <div class="relative mt-1 rounded-md">
                                            <select
                                                v-model="
                                                    deliveryCheckoutSettings.phone
                                                "
                                                class="form-input block w-1/2 shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            >
                                                <option
                                                    v-for="option in phoneOptions"
                                                    :key="option"
                                                    :value="option"
                                                    class="text-gray-500"
                                                >
                                                    <span>{{ option }}</span>
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div
                                        class="col-span-6 sm:col-span-6 lg:col-span-6"
                                    >
                                        <span
                                            class="text-sm leading-5 text-gray-500"
                                        >
                                            Email
                                        </span>
                                        <div class="relative mt-1 rounded-md">
                                            <select
                                                v-model="
                                                    deliveryCheckoutSettings.email
                                                "
                                                class="form-input block w-1/2 shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            >
                                                <option
                                                    v-for="option in emailOptions"
                                                    :key="option"
                                                    :value="option"
                                                    class="text-gray-500"
                                                >
                                                    <span>{{ option }}</span>
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="my-3 flex items-start">
                                        <div class="flex h-5 items-center">
                                            <toggle-switch
                                                v-model="
                                                    deliveryCheckoutSettings.firstName
                                                "
                                            />
                                        </div>
                                        <div class="pl-7 text-sm leading-5">
                                            <p class="text-gray-500">
                                                First name
                                            </p>
                                        </div>
                                    </div>
                                    <div class="my-3 flex items-start">
                                        <div class="flex h-5 items-center">
                                            <toggle-switch
                                                v-model="
                                                    deliveryCheckoutSettings.lastName
                                                "
                                            />
                                        </div>
                                        <div class="pl-7 text-sm leading-5">
                                            <p class="text-gray-500">
                                                Last name
                                            </p>
                                        </div>
                                    </div>
                                    <div class="my-3 flex items-start">
                                        <div class="flex h-5 items-center">
                                            <toggle-switch
                                                v-model="
                                                    deliveryCheckoutSettings.cutlery
                                                "
                                            />
                                        </div>
                                        <div class="pl-7 text-sm leading-5">
                                            <p class="text-gray-500">
                                                Cutlery
                                            </p>
                                        </div>
                                    </div>
                                    <div class="my-3 flex items-start">
                                        <div class="flex h-5 items-center">
                                            <toggle-switch
                                                v-model="
                                                    deliveryCheckoutSettings.companyName
                                                "
                                            />
                                        </div>
                                        <div class="pl-7 text-sm leading-5">
                                            <p class="text-gray-500">
                                                Company name
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <legend
                                        class="text-base font-medium text-gray-900"
                                        >Pickup</legend
                                    >
                                    <div
                                        class="col-span-6 sm:col-span-6 lg:col-span-6"
                                    >
                                        <span
                                            class="text-sm leading-5 text-gray-500"
                                        >
                                            Phone
                                        </span>
                                        <div class="relative mt-1 rounded-md">
                                            <select
                                                v-model="
                                                    pickupCheckoutSettings.phone
                                                "
                                                class="form-input block w-1/2 shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            >
                                                <option
                                                    v-for="option in phoneOptions"
                                                    :key="option"
                                                    :value="option"
                                                    class="text-gray-500"
                                                >
                                                    <span>{{ option }}</span>
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div
                                        class="col-span-6 sm:col-span-6 lg:col-span-6"
                                    >
                                        <span
                                            class="text-sm leading-5 text-gray-500"
                                        >
                                            Email
                                        </span>
                                        <div class="relative mt-1 rounded-md">
                                            <select
                                                v-model="
                                                    pickupCheckoutSettings.email
                                                "
                                                class="form-input block w-1/2 shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            >
                                                <option
                                                    v-for="option in emailOptions"
                                                    :key="option"
                                                    :value="option"
                                                    class="text-gray-500"
                                                >
                                                    <span>{{ option }}</span>
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="my-3 flex items-start">
                                        <div class="flex h-5 items-center">
                                            <toggle-switch
                                                v-model="
                                                    pickupCheckoutSettings.firstName
                                                "
                                            />
                                        </div>
                                        <div class="pl-7 text-sm leading-5">
                                            <p class="text-gray-500">
                                                First name
                                            </p>
                                        </div>
                                    </div>
                                    <div class="my-3 flex items-start">
                                        <div class="flex h-5 items-center">
                                            <toggle-switch
                                                v-model="
                                                    pickupCheckoutSettings.lastName
                                                "
                                            />
                                        </div>
                                        <div class="pl-7 text-sm leading-5">
                                            <p class="text-gray-500">
                                                Last name
                                            </p>
                                        </div>
                                    </div>
                                    <div class="my-3 flex items-start">
                                        <div class="flex h-5 items-center">
                                            <toggle-switch
                                                v-model="
                                                    pickupCheckoutSettings.cutlery
                                                "
                                            />
                                        </div>
                                        <div class="pl-7 text-sm leading-5">
                                            <p class="text-gray-500">
                                                Cutlery
                                            </p>
                                        </div>
                                    </div>
                                    <div class="my-3 flex items-start">
                                        <div class="flex h-5 items-center">
                                            <toggle-switch
                                                v-model="
                                                    pickupCheckoutSettings.companyName
                                                "
                                            />
                                        </div>
                                        <div class="pl-7 text-sm leading-5">
                                            <p class="text-gray-500">
                                                Company name
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="hidden sm:block" aria-hidden="true">
            <div class="py-5">
                <div class="border-t border-gray-200"></div>
            </div>
        </div>

        <order-notifications
            :emailNotifications="operations.emailNotifications"
            :customerSmsNotification="operations.customerSmsNotification"
            :payment-failed-notification="operations.paymentFailedNotification"
            :isOrderAndPay="isOrderAndPay"
            :has-payments="!!operations.activePaymentSystem"
            :sms-name="operations.smsName"
            @change-operation-property="changeOperationProperty"
        />

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Manager PIN
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Require a PIN code for restricted actions (refunds).
                            Once enabled, users will need to enter the correct
                            PIN code to process a refund.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <fieldset>
                                <legend
                                    class="text-base font-medium leading-6 text-gray-900"
                                >
                                    Manager PIN
                                </legend>
                                <div class="mt-4">
                                    <div class="flex items-start">
                                        <toggle-switch
                                            :value="!!operations.managerPin"
                                            @input="handlePinChange"
                                        />
                                    </div>
                                </div>
                            </fieldset>
                            <div v-if="operations.managerPin" class="mt-3">
                                <button
                                    @click="changeManagerPin()"
                                    type="button"
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                >
                                    Change PIN
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Fees
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Optionally charge additional fees to your customers
                            on every order placed.
                        </p>
                        <p class="mt-2 text-sm text-gray-500">
                            Delivery zones will override the default delivery
                            fee.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-6 gap-6">
                                <div
                                    v-if="!isOrderAndPay"
                                    class="col-span-6 sm:col-span-6"
                                >
                                    <label
                                        for="deliveryFee"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Delivery Fee</label
                                    >
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                        >Charged on delivery orders</span
                                    >
                                    <div
                                        class="relative mt-1 rounded-md shadow-sm"
                                    >
                                        <div
                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                        >
                                            <span
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                {{ currency.symbol }}
                                            </span>
                                        </div>
                                        <currency-input
                                            id="deliveryFee"
                                            v-model="operations.deliveryFee"
                                            class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                            :class="{
                                                'border-red-300':
                                                    $v.operations.deliveryFee
                                                        .$error
                                            }"
                                            placeholder="0.00"
                                            :currency="null"
                                            locale="en-GB"
                                            :value-as-integer="true"
                                            :allow-negative="false"
                                        ></currency-input>
                                        <div
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                        >
                                            <span
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                {{ currency.ISO }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <label
                                        for="serviceCharge"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Fixed Charge</label
                                    >
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                        >Charged on every order</span
                                    >
                                    <div
                                        class="relative mt-1 rounded-md shadow-sm"
                                    >
                                        <div
                                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                        >
                                            <span
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                {{ currency.symbol }}
                                            </span>
                                        </div>
                                        <currency-input
                                            id="serviceCharge"
                                            name="serviceCharge"
                                            v-model="operations.serviceCharge"
                                            class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                            placeholder="0.00"
                                            :class="{
                                                'border-red-300':
                                                    $v.operations.serviceCharge
                                                        .$error
                                            }"
                                            :currency="null"
                                            locale="en-GB"
                                            :value-as-integer="true"
                                            :allow-negative="false"
                                        ></currency-input>
                                        <!--<input id="serviceCharge" v-model="venueSettings.serviceCharge" />-->
                                        <div
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                        >
                                            <span
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                {{ currency.ISO }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <label
                                        for="serviceChargeLabel"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Label
                                    </label>
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                    >
                                        Rename the fixed charge label
                                    </span>
                                    <div
                                        class="relative mt-1 rounded-md shadow-sm"
                                    >
                                        <input
                                            v-model="
                                                operations.serviceChargeLabel
                                            "
                                            id="serviceChargeLabel"
                                            name="serviceChargeLabel"
                                            placeholder="Service charge"
                                            type="text"
                                            class="form-input block w-full pr-16 sm:text-sm sm:leading-5"
                                            maxlength="80"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                        >
                                            <span
                                                v-if="
                                                    operations.serviceChargeLabel
                                                "
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                {{
                                                    operations
                                                        .serviceChargeLabel
                                                        .length
                                                }}/80
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <batch-settings
            v-if="isOrderAndPay"
            :batchSettings="venue.batchSettings"
            :isBatchSettingActive="isBatchSettingActive"
            :areBatchNotesActive="areBatchNotesActive"
            :areBatchCustomerNamesActive="areBatchCustomerNamesActive"
            :batchTimeout="batchTimeout"
            @change-is-batch-setting-active="changeIsBatchSettingActive"
            @change-are-batch-notes-active="changeAreBatchNotesActive"
            @change-are-batch-names-active="changeAreBatchCustomerNamesActive"
            @change-batch-timeout="changeBatchTimeout"
        />

        <BaseSpacerWithRuler />

        <auto-accept-settings
            :autoAccept="operations.autoAccept"
            @change-operation-property="changeOperationProperty"
        />

        <BaseSpacerWithRuler />

        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Order ID format
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Shown on the Orders page, customer pages, and
                            customer order notifications to identify orders.
                        </p>
                        <p class="mt-2 text-sm text-gray-500">
                            Customize your order ID format by changing the
                            prefix. Changes are applied to future orders
                        </p>
                    </div>
                </div>
                <div
                    class="mt-5 space-y-6 bg-white px-4 py-5 shadow sm:overflow-hidden sm:rounded-md sm:p-6 md:col-span-2 md:mt-0"
                >
                    <label
                        for="orderNumberPrefix"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >
                        Prefix
                    </label>
                    <div class="relative mt-1 rounded-md shadow-sm">
                        <input
                            v-model="operations.orderNumberPrefix"
                            id="orderNumberPrefix"
                            name="orderNumberPrefix"
                            placeholder=""
                            type="text"
                            class="form-input block w-full pr-12 sm:text-sm sm:leading-5"
                            :class="{
                                'border-red-300':
                                    $v.operations.orderNumberPrefix.$error
                            }"
                            maxlength="3"
                        />
                    </div>
                    <span class="text-sm leading-5 text-gray-500">
                        Your order ID will appear as
                        {{ operations.orderNumberPrefix }}1001,
                        {{ operations.orderNumberPrefix }}1002,
                        {{ operations.orderNumberPrefix }}1003...
                    </span>
                </div>
            </div>
        </div>

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>

        <ManagerPinSettingsModal @pin-set="pinSet()" />
        <ManagerPinChangeModal @pin-changed="pinChanged()" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
    required,
    maxLength,
    minLength,
    alphaNum
} from 'vuelidate/lib/validators';
import { FeaturesEnum, FulfillmentMethodsEnum } from '@/enums';
import BatchSettings from './operations/BatchSettings';
import AutoAcceptSettings from './operations/AutoAcceptSettings';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import ManagerPinSettingsModal from '@/components/modals/ManagerPinSettingsModal';
import ManagerPinChangeModal from '@/components/modals/ManagerPinChangeModal';
import OrderNotifications from './operations/OrderNotifications';
import LargeRadioButton from '@/components/formComponents/LargeRadioButton';

export default {
    name: 'OperationsSettings',
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        },
        venue: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },
    metaInfo: {
        title: 'Operations Settings'
    },
    components: {
        BatchSettings,
        AutoAcceptSettings,
        OrderNotifications,
        ToggleSwitch,
        ManagerPinSettingsModal,
        ManagerPinChangeModal,
        LargeRadioButton
    },
    data() {
        return {
            isLoading: false,
            primaryColour: '#161e2e',
            operations: { ...this.venue },
            isBatchSettingActive: this.venue.batchSettings.enabled,
            areBatchNotesActive: this.venue.batchSettings.showNotes,
            areBatchCustomerNamesActive: this.venue.batchSettings
                .showCustomerNames,
            batchTimeout: this.venue.batchSettings.timeout,
            phoneOptions: ['enabled', 'disabled', 'validated'],
            emailOptions: ['required', 'disabled', 'optional'],
            inStoreCheckoutSettings: {
                phone: 'enabled',
                email: 'required',
                firstName: true,
                lastName: true,
                cutlery: true,
                companyName: false
            },
            pickupCheckoutSettings: {
                phone: 'enabled',
                email: 'required',
                firstName: true,
                lastName: true,
                cutlery: true,
                companyName: false
            },
            deliveryCheckoutSettings: {
                phone: 'enabled',
                email: 'required',
                firstName: true,
                lastName: true,
                cutlery: true,
                companyName: false
            },
            FeaturesEnum,
            customCheckoutSettings: false,
            itemNotesOptions: [
                {
                    label: 'Optional',
                    labelDescription: 'Allow customers to enter item notes.',
                    value: true
                },
                {
                    label: 'Hidden',
                    labelDescription:
                        'Hides the item note field and does not allow customers to add item notes.',
                    value: false
                }
            ],
            orderNotesOptions: [
                {
                    label: 'Optional',
                    labelDescription:
                        'Allow customers to enter an order note at checkout.',
                    value: 'optional'
                },
                {
                    label: 'Mandatory',
                    labelDescription:
                        'Requires customers to enter an order note at checkout. They cannot proceed without providing a note.',
                    value: 'mandatory'
                },
                {
                    label: 'Hidden',
                    labelDescription:
                        'Hides the order note field and does not allow customers to add order notes at the checkout stage.',
                    value: 'hidden'
                }
            ]
        };
    },
    validations: {
        operations: {
            emailNotifications: {
                required
            },
            acceptsDelivery: {
                required
            },
            acceptsPickup: {
                required
            },
            acceptsPreOrders: {
                required
            },
            prepTime: {
                required
            },
            collectionPrepTime: {
                required
            },
            serviceCharge: {
                required
            },
            deliveryFee: {
                required
            },
            minDeliveryAmount: {
                required
            },
            minPickupAmount: {
                required
            },
            enableOrderNotes: {
                required
            },
            minInStoreAmount: {
                required
            },
            autoAccept: {
                required
            },
            customerSmsNotification: {
                required
            },
            paymentFailedNotification: {
                required
            },
            maxDeliveryAmount: {
                required
            },
            maxPickupAmount: {
                required
            },
            maxInStoreAmount: {
                required
            },
            orderNumberPrefix: {
                maxLength: maxLength(3),
                minLength: minLength(3),
                alphaNum
            }
        }
    },
    methods: {
        ...mapActions({
            updateVenueSettings: 'venues/update'
        }),
        async saveVenue() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.errors = 'ERROR';

                return false;
            }

            try {
                this.isLoading = true;

                const batchSettings = {
                    enabled: this.isBatchSettingActive,
                    showNotes: this.areBatchNotesActive,
                    showCustomerNames: this.areBatchCustomerNamesActive,
                    timeout: this.batchTimeout
                };

                const payload = {
                    id: this.venueId,
                    emailNotifications: this.operations.emailNotifications,
                    acceptsDelivery: this.operations.acceptsDelivery,
                    acceptsPickup: this.operations.acceptsPickup,
                    deliveryFee: this.operations.deliveryFee,
                    serviceCharge: this.operations.serviceCharge,
                    serviceChargeLabel: this.operations.serviceChargeLabel,
                    prepTime: this.operations.prepTime,
                    collectionPrepTime: this.operations.collectionPrepTime,
                    minDeliveryAmount: this.operations.minDeliveryAmount,
                    minPickupAmount: this.operations.minPickupAmount,
                    enableOrderNotes: this.operations.enableOrderNotes,
                    enableItemNotes: this.operations.enableItemNotes,
                    batchSettings,
                    minInStoreAmount: this.operations.minInStoreAmount,
                    autoAccept: this.operations.autoAccept,
                    customerSmsNotification: this.operations
                        .customerSmsNotification,
                    paymentFailedNotification: this.operations
                        .paymentFailedNotification,
                    maxDeliveryAmount: this.operations.maxDeliveryAmount,
                    maxPickupAmount: this.operations.maxPickupAmount,
                    maxInStoreAmount: this.operations.maxInStoreAmount,
                    smsName: this.operations.smsName,
                    orderNumberPrefix: this.operations.orderNumberPrefix
                };

                if (this.customCheckoutSettings) {
                    payload.checkoutSettings = this.isOrderAndPay
                        ? [
                              {
                                  ...this.inStoreCheckoutSettings,
                                  fulfillmentMethod:
                                      FulfillmentMethodsEnum.IN_STORE
                              }
                          ]
                        : [
                              {
                                  ...this.pickupCheckoutSettings,
                                  fulfillmentMethod:
                                      FulfillmentMethodsEnum.PICKUP
                              },
                              {
                                  ...this.deliveryCheckoutSettings,
                                  fulfillmentMethod:
                                      FulfillmentMethodsEnum.DELIVERY
                              }
                          ];
                }

                await this.updateVenueSettings(payload);
                this.updateCheckoutSettings();

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async togglePatVisual() {
            try {
                this.isLoading = true;

                const payload = {
                    id: this.venueId,
                    acceptsPat: !this.operations.acceptsPat
                };

                await this.updateVenueSettings(payload);

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async toggleInVenue() {
            try {
                this.isLoading = true;

                const payload = {
                    id: this.venueId,
                    acceptsInStore: !this.operations.acceptsInStore
                };

                await this.updateVenueSettings(payload);

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        changeIsBatchSettingActive(value) {
            this.isBatchSettingActive = value;
        },
        changeAreBatchNotesActive(value) {
            this.areBatchNotesActive = value;
        },
        changeAreBatchCustomerNamesActive(value) {
            this.areBatchCustomerNamesActive = value;
        },
        changeBatchTimeout(value) {
            this.batchTimeout = value;
        },
        changeOperationProperty(property, value) {
            this.operations[property] = value;
        },
        changeManagerPin() {
            if (!this.operations.managerPin) {
                return;
            }

            this.$modal.show('manager-pin-change-modal', {
                venueId: this.venueId
            });
        },
        handlePinChange() {
            if (this.operations.managerPin) {
                this.$notify({
                    group: 'settings',
                    title:
                        'Manager PIN is already enabled, ask support to disable it.'
                });

                return;
            }

            this.$modal.show('manager-pin-settings-modal', {
                venueId: this.venueId
            });
        },
        pinSet() {
            this.operations.managerPin = true;
            this.$notify({
                group: 'settings',
                title: 'Manager PIN set'
            });
        },
        pinChanged() {
            this.$notify({
                group: 'settings',
                title: 'Manager PIN changed'
            });
        },

        updateCheckoutSettings() {
            if (this.isOrderAndPay) {
                const instoreInfo = this.venue.checkoutSettings.find(
                    ({ fulfillmentMethod }) =>
                        fulfillmentMethod === FulfillmentMethodsEnum.IN_STORE
                );

                if (instoreInfo) {
                    this.customCheckoutSettings = true;
                    this.inStoreCheckoutSettings = instoreInfo;
                }
            } else {
                const deliveryInfo = this.venue.checkoutSettings.find(
                    ({ fulfillmentMethod }) =>
                        fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY
                );

                if (deliveryInfo) {
                    this.customCheckoutSettings = true;
                    this.deliveryCheckoutSettings = deliveryInfo;
                }

                const pickupInfo = this.venue.checkoutSettings.find(
                    ({ fulfillmentMethod }) =>
                        fulfillmentMethod === FulfillmentMethodsEnum.PICKUP
                );

                if (pickupInfo) {
                    this.customCheckoutSettings = true;
                    this.pickupCheckoutSettings = pickupInfo;
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            currency: 'user/currencySettings',
            accountId: 'user/accountId',
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        isOrderAndPay() {
            return this.venue.acceptsInStore;
        }
    },

    created() {
        this.updateCheckoutSettings();
    }
};
</script>
