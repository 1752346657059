module.exports = class DeliveryProvidersEnum {
    static get ORKESTRO() {
        return 'orkestro';
    }

    static get NASH() {
        return 'nash';
    }

    static get SHIPDAY() {
        return 'shipday';
    }

    static get options() {
        return {
            ORKESTRO: this.ORKESTRO,
            NASH: this.NASH,
            SHIPDAY: this.SHIPDAY
        };
    }
};
