var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customer-card col-span-3 flex flex-col overflow-hidden bg-white shadow sm:rounded-md lg:col-span-1 print:col-span-3 print:flex-row"},[_c('div',{staticClass:"px-2 py-2 sm:px-5 sm:py-2.5"},[_c(_vm.order.customer.email ? 'router-link' : 'span',{tag:"router-link",staticClass:"block rounded-md p-1 transition-colors duration-200 ease-in-out hover:bg-gray-50",attrs:{"to":{
                name: 'customerDetail',
                params: { email: _vm.order.customer.email }
            },"title":"View customer profile"}},[_c('div',{staticClass:"flex items-center"},[(_vm.order.customer.firstName)?_c('div',{staticClass:"mr-2"},[_c('avatar',{attrs:{"username":_vm.order.customer.firstName +
                            ' ' +
                            _vm.order.customer.lastName,"size":32}})],1):_vm._e(),_c('div',{staticClass:"text-sm w-full"},[_c('p',{staticClass:"font-heading leading-none text-gray-900"},[_vm._v(" "+_vm._s(_vm.order.customer.firstName || 'Guest')+" "+_vm._s(_vm.order.customer.lastName)+" ")]),(_vm.order.customer.email)?_c('p',{directives:[{name:"auto-animate",rawName:"v-auto-animate"}],staticClass:"mt-1 text-xs text-gray-600"},[(
                                !_vm.customerIsLoading &&
                                _vm.customer.orderCount &&
                                _vm.customer.orderCount > 0
                            )?_c('span',[_vm._v(" "+_vm._s(_vm.customerOrderCount))]):_c('span',{staticClass:"inline-flex opacity-70"},[_c('span',{attrs:{"id":"blink"}},[_vm._v(".")]),_c('span',{attrs:{"id":"blink"}},[_vm._v(".")]),_c('span',{attrs:{"id":"blink"}},[_vm._v(".")])])]):_vm._e()])])])],1),(
            (_vm.order.review && _vm.order.review.rating) ||
            (_vm.customer && _vm.customer.reviewsCount > 0)
        )?_c('div',{staticClass:"border-t border-gray-100 px-2 py-2 sm:px-5 sm:py-2.5"},[(_vm.order.review && _vm.order.review.rating)?_c('div',[_c('div',{staticClass:"flex flex-row items-center"},[_c('h3',{staticClass:"text-lg font-bold text-gray-700"},[_vm._v(" "+_vm._s(_vm.order.review.rating || 0)+" ")]),_c('div',{staticClass:"mb-1.5 block pl-2"},[_c('star-rating',{attrs:{"show-rating":false,"star-size":16,"padding":2,"active-color":"#fabe00","increment":0.01,"read-only":true},model:{value:(_vm.order.review.rating),callback:function ($$v) {_vm.$set(_vm.order.review, "rating", $$v)},expression:"order.review.rating"}})],1)]),_c('span',{staticClass:"mb-3 block text-xs tracking-wide text-gray-500"},[_vm._v(" Rating of the current order ")])]):_vm._e(),(
                _vm.customer &&
                _vm.customer.averageReviewsRating &&
                _vm.customer.reviewsCount > 0
            )?_c('div',[_c('div',{staticClass:"flex flex-row items-center"},[_c('h3',{staticClass:"text-lg font-bold text-gray-700"},[_vm._v(" "+_vm._s(_vm.customer.averageReviewsRating)+" ")]),_c('div',{staticClass:"mb-1.5 block pl-2"},[_c('star-rating',{attrs:{"show-rating":false,"star-size":16,"padding":2,"active-color":"#fabe00","increment":0.01,"read-only":true},model:{value:(_vm.customer.averageReviewsRating),callback:function ($$v) {_vm.$set(_vm.customer, "averageReviewsRating", $$v)},expression:"customer.averageReviewsRating"}})],1)]),(_vm.customer.reviewsCount)?_c('span',{staticClass:"mb-1 block text-xs tracking-wide text-gray-500"},[_vm._v(" Average rating from "+_vm._s(_vm.customer.reviewsCount)+" reviews ")]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.order.customer.phone || _vm.order.customer.email)?_c('div',{staticClass:"border-t border-gray-100 px-4 py-3 sm:px-6"},[(_vm.order.customer.phone)?_c('div',{staticClass:"flex items-center text-sm leading-5 text-gray-400"},[_c('base-phone-icon',{staticClass:"mr-2 h-4 w-4 flex-shrink-0"}),_c('a',{staticClass:"text-gray-900",attrs:{"href":'tel:' + _vm.order.customer.phone,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.order.customer.phone)+" ")])],1):_vm._e(),(_vm.order.customer.email)?_c('div',{staticClass:"flex items-center text-sm leading-5 text-gray-400",class:{ 'mt-2' : _vm.order.customer.phone }},[_c('base-email-icon',{staticClass:"mr-2 flex-shrink-0"}),_c('a',{staticClass:"overflow-hidden text-indigo-600",attrs:{"href":'mailto:' + _vm.order.customer.email,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.order.customer.email)+" ")])],1):_vm._e()]):_vm._e(),(_vm.order.fulfillmentMethod === 'Delivery')?_c('div',[(_vm.deliveryAddressCoordinates)?_c('div',{staticClass:"map-container h-64 w-full print:absolute print:left-0 print:w-full"},[_c('OrderDeliveryAddressMap',{attrs:{"address":_vm.order.deliveryAddress,"coordinates":_vm.deliveryAddressCoordinates}})],1):_vm._e()]):_vm._e(),(
            _vm.order.customer &&
            _vm.order.customer.piggy &&
            _vm.order.customer.piggy.credits > 0
        )?_c('div',{staticClass:"border-t border-gray-100 bg-gray-50 px-2 py-2 text-sm sm:px-5 sm:py-3"},[(_vm.order.customer.piggy.checkbox)?_c('div',{staticClass:"flex cursor-pointer justify-between text-gray-800"},[_c('div',{staticClass:"flex items-center justify-between hover:underline",on:{"click":_vm.openInPiggy}},[_c('span',[_vm._v(" Loyalty profile ")]),_c('base-arrow-box-icon',{staticClass:"ml-1 h-4 w-4"})],1),_c('span',{staticClass:"cursor-pointer hidden hover:underline",on:{"click":function($event){return _vm.reversePiggyTransaction()}}},[_vm._v(" Reverse ")])]):_vm._e(),(_vm.piggyLoading)?_c('div',{staticClass:"flex items-center justify-center h-32"},[_c('div',{staticClass:"spinner"})]):_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"block text-gray-800"},[_vm._v(" Earned: "+_vm._s(_vm.order.customer.piggy.credits)+" credits ")]),(_vm.customerLoyalty?.current_display_values?.loyalty_balance)?_c('p',{staticClass:"text-gray-800"},[_vm._v("Balance: "+_vm._s(_vm.customerLoyalty.current_display_values.loyalty_balance)+" ")]):_vm._e(),(_vm.customerLoyalty?.current_display_values?.loyalty_balance)?_c('p',{staticClass:"text-gray-800"},[_vm._v("Transactions: "+_vm._s(_vm.customerLoyalty.current_display_values.loyalty_number_of_transactions)+" ")]):_vm._e(),(_vm.customerLoyalty?.current_display_values?.tier)?_c('p',{staticClass:"text-gray-800"},[_vm._v("Tier: "),_c('span',{staticClass:"px-1.5 py-0.5 bg-gray-200 rounded"},[_vm._v(_vm._s(_vm.customerLoyalty.current_display_values.tier)+" ")])]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }