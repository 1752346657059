<template>
    <div class="fullscreen-container mx-auto flex w-full flex-grow">
        <div class="orders-list flex min-w-0 flex-1 bg-white print:hidden">
            <div class="min-w-0 flex-1 bg-white">
                <div>
                    <div
                        class="border-b border-t border-gray-200 p-3"
                        :class="{ 'animate-pulse bg-red-50': connectionError }"
                    >
                        <div class="flex items-start">
                            <div class="flex items-center space-x-3">
                                <span
                                    aria-label="Running"
                                    class="flex h-4 w-4 items-center justify-center rounded-full"
                                    :class="{
                                        animate: isPolling,
                                        'bg-red-100': connectionError,
                                        'bg-green-100': !connectionError
                                    }"
                                >
                                    <span
                                        class="h-2 w-2 rounded-full"
                                        :class="{
                                            'bg-green-400': !connectionError,
                                            'bg-red-400': connectionError
                                        }"
                                    ></span>
                                </span>
                                <div
                                    v-if="connectionError"
                                    class="flex flex-col"
                                >
                                    <h1 class="text-sm font-medium">
                                        Connection Error
                                    </h1>
                                    <span class="text-xs text-gray-700">
                                        Please check your internet connection
                                    </span>
                                </div>
                            </div>
                            <div
                                class="relative flex w-full flex-row justify-end"
                                v-if="!connectionError"
                            >
                                <button
                                    @click="openFilterSidebar"
                                    type="button"
                                    class="focus:outline-none inline-flex items-center items-center rounded-md border bg-white px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    :class="
                                        selectedFilters
                                            ? 'border-indigo-600 text-indigo-600'
                                            : 'border-gray-300 text-gray-700'
                                    "
                                >
                                    Filters
                                    <span
                                        v-if="selectedFilters"
                                        class="ml-2 h-4 w-4 rounded-full bg-indigo-600 text-xs font-bold text-white"
                                    >
                                        {{ selectedFilters }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="h-full flex-1 overflow-y-auto pb-36"
                    :class="{ spinner: isLoading }"
                    v-auto-animate
                >
                    <div
                        class="relative z-0 cursor-pointer divide-y divide-gray-200 border-b border-gray-200"
                        v-for="order in orders"
                        :key="order.id"
                        @click="selectOrder(order)"
                    >
                        <LiveOrderListItem
                            :order="order"
                            :class="{
                                'bg-gray-100': selectedOrder.id === order.id
                            }"
                            :table-id="selectedTableId"
                            :selected-venues-count="
                                Array.isArray(venueFilter)
                                    ? venueFilter.length
                                    : 0
                            "
                            :venues-count="
                                Array.isArray(venues) ? venues.length : 0
                            "
                        />
                    </div>
                    <div
                        class="flex h-full items-center justify-center"
                        v-if="!isLoading && !orders.length"
                    >
                        <h5 class="font-bold text-gray-700">
                            No orders for today yet
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="flex w-0 flex-1 flex-col bg-sk-silver-grey lg:flex-shrink-0 lg:border-l lg:border-gray-200 xl:pr-0 print:w-full"
        >
            <div
                v-if="!isWebExtension"
                class="hidden justify-end border-b border-t border-gray-200 bg-white pb-4 pl-4 pr-6 pt-4 sm:flex sm:pl-6 lg:pl-8 xl:border-t-0 xl:pl-6 xl:pt-6 print:hidden"
                style="height: 64px"
            >
                <button
                    @click="toggleFullScreen"
                    type="button"
                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    <svg
                        class="-ml-0.5 mr-2 h-4 w-4"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                    Fullscreen
                </button>
                <router-link
                    :to="{ name: 'dashboard' }"
                    v-if="
                        $store.state.user &&
                        $store.state.user.profile &&
                        $store.state.user.profile.id !== 1828
                    "
                    class="focus:outline-none ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    <svg
                        class="-ml-0.5 mr-2 h-4 w-4"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                    Exit
                </router-link>
            </div>
            <Order
                v-show="selectedOrder && selectedOrder.id"
                @select="order => (this.selectedOrder = order)"
                class="w-full"
                :key="selectedOrder?.id"
            />
        </div>

        <live-orders-filter-sidebar
            v-model="isFilterSidebarOpen"
            :venues="venues"
            :venueFilter="venueFilter"
            :venueTables="venueTables"
            :showCompleted="showCompleted"
            :showFailed="showFailed"
            @setVenue="setVenue"
            @setTable="setTable"
            @toggleCompleted="toggleCompleted"
            @toggleFailed="toggleFailed"
            @clearFilters="clearFilters"
        />
    </div>
</template>

<script>
import { Howl } from 'howler';
import { api as fullscreen } from 'vue-fullscreen';
import qs from 'qs';
import vClickOutside from 'v-click-outside';
import ably from '@/helpers/ably';
import { mapActions, mapGetters } from 'vuex';
import Order from './Order';
import LiveOrderListItem from '@/components/LiveOrderListItem';
import LiveOrdersFilterSidebar from '@/components/sidebars/LiveOrdersFilterSidebar';

export default {
    name: 'LiveOrders',
    metaInfo: {
        title: 'Live Orders'
    },
    data() {
        return {
            isLoading: true,
            orders: [],
            venueTables: [],
            ordersLength: 0,
            orderFilter: 'All',
            showOrderFilter: false,
            showRejected: false,
            showCompleted: true,
            showFailed: false,
            showVenueFilter: false,
            venueFilter: '',
            polling: null,
            currentPage: 1,
            pageLimit: 100,
            totalPages: null,
            selectedOrder: {},
            isPolling: false,
            selectedTableId: null,
            connectionError: false,
            isFilterSidebarOpen: false,
            fullscreen: false,
            tableFilter: null,
            channels: [],
            lastOrderDate: null,
            lastPreOrderDate: null
        };
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    components: {
        LiveOrderListItem,
        Order,
        LiveOrdersFilterSidebar
    },
    mounted() {
        ably.connection.on('connected', () => {
            this.connectionError = false;
        });
        ably.connection.on('disconnected', () => {
            this.connectionError = true;
        });
        ably.connection.on('failed', () => {
            this.connectionError = true;
        });
        ably.connection.on('suspended', () => {
            this.connectionError = true;
        });
    },
    watch: {
        currentPage: function () {
            this.fetchOrders(false);
        },
        venueFilter: function (newValue, oldValue) {
            this.venueTables = [];

            if (newValue && newValue.length === 1) {
                this.fetchTables();
            }

            if (!newValue && oldValue) {
                newValue = [];
                for (const venue of this.venues) {
                    if (venue.id) {
                        newValue.push(venue.id);
                    }
                }
            }
            if (!oldValue || !oldValue.length) {
                oldValue = [];

                this.unsubscribeAll();
            }
            if (Array.isArray(oldValue) && oldValue.length) {
                const venueIds = oldValue.filter(n => !newValue.includes(n));

                if (venueIds) {
                    for (const venueId of venueIds) {
                        this.unsubscribeById(venueId);
                    }
                }
            }
            if (!newValue || !newValue.length) {
                newValue = [];

                if (oldValue && oldValue.length) {
                    this.subscribeAll();
                }
            }
            if (Array.isArray(newValue) && newValue.length) {
                const venueIds = newValue.filter(n => !oldValue.includes(n));

                if (venueIds) {
                    for (const venueId of venueIds) {
                        this.subscribeById(venueId);
                    }
                }
            }
        },
        venues: function () {
            if (!this.venueFilter) {
                this.subscribeAll();
            }
        }
    },
    async created() {
        await this.init();
    },
    beforeDestroy() {
        this.unsubscribeAll();
    },
    computed: {
        ...mapGetters({
            isWebExtension: 'ui/isWebExtension',
            venues: 'venues/getVenues',
            accountId: 'user/accountId'
        }),
        selectedFilters() {
            let countSelected = 0;

            if (this.venueFilter) {
                countSelected++;
            }

            if (this.tableFilter) {
                countSelected++;
            }

            if (!this.showCompleted) {
                countSelected++;
            }

            if (this.showFailed) {
                countSelected++;
            }

            return countSelected;
        }
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        clearFilters() {
            this.showCompleted = true;
            this.showFailed = false;
            this.tableFilter = null;
            this.venueFilter = '';

            this.fetchOrders(false, false);
        },
        openFilterSidebar() {
            this.isFilterSidebarOpen = true;
        },
        selectOrder(order) {
            this.selectedOrder = order;

            if (order.id === this.$route.params.id) {
                return;
            }

            if (order.tableId) {
                this.selectedTableId = order.tableId;
            }

            this.$router.replace({
                name: 'liveOrders',
                params: { id: this.selectedOrder.id }
            });
        },
        venueById(id) {
            return this.venues.find(venue => venue.id === id);
        },
        unsubscribeById(venueId) {
            if (!venueId) {
                return;
            }

            if (this.channels[`live-order-${venueId}`]) {
                this.channels[`live-order-${venueId}`].detach();
            }
        },
        unsubscribeAll() {
            for (const venue of this.venues) {
                this.unsubscribeById(venue.id);
            }
        },
        subscribeById(venueId) {
            if (!venueId) {
                return;
            }

            const channel = ably.channels.get(`live-order-${venueId}`);

            channel.subscribe(`live-order-event-${venueId}`, event => {
                this.fetchOrders(event.data === 'order-created', true);
            });

            this.channels[`live-order-${venueId}`] = channel;
        },
        subscribeAll() {
            for (const venue of this.venues) {
                this.subscribeById(venue.id);
            }
        },
        async fetchTables() {
            const venueId = parseInt(this.venueFilter[0]);

            const { acceptsInStore } = this.venues.find(
                venue => venue.id === venueId
            );

            if (!acceptsInStore) {
                return;
            }

            try {
                const { data } = await this.$axios.get(
                    `/venues/${venueId}/tables`
                );

                this.venueTables = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async init() {
            this.isLoading = true;

            try {
                // Incipio
                if (this.accountId === '2f8da1f5-7220-4bc9-ae9c-3a184e486932') {
                    this.showCompleted = false;
                }

                if (!this.venues?.length > 0) {
                    await this.fetchVenues();
                }

                if (!this.venues?.length > 0) {
                    return;
                }

                if (!localStorage.getItem('storekit.live-order-venue-filter')) {
                    return;
                }

                const venueId = localStorage.getItem(
                    'storekit.live-order-venue-filter'
                );

                if (venueId.includes(',')) {
                    this.venueFilter = venueId.split(',').map(Number);

                    return;
                }

                if (this.venues.some(venue => venue.id === +venueId)) {
                    this.venueFilter = venueId;

                    return;
                }

                this.subscribeAll();

                localStorage.removeItem('storekit.live-order-venue-filter');
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
                this.fetchOrders(false, false);
            }
        },
        async fetchOrders(playSound, isPolling) {
            if (this.isLoading || this.isPolling) {
                return;
            }

            if (!isPolling) {
                this.isLoading = true;
            } else {
                this.isPolling = true;
            }

            const params = {
                showRejected: this.showRejected,
                showCompleted: this.showCompleted,
                page: this.currentPage,
                limit: this.pageLimit,
                tableId: this.tableFilter,
                status: this.showFailed ? 'Failed' : null
            };

            if (this.venueFilter) {
                params.venueId = this.venueFilter;
            }

            try {
                const { data } = await this.$axios.get('/live-orders', {
                    params,
                    paramsSerializer: {
                        serialize: params => {
                            return qs.stringify(params, {
                                arrayFormat: 'repeat',
                                skipNulls: true
                            });
                        }
                    }
                });

                if (!data) {
                    return;
                }

                if (playSound && this.newerOrderExist(data.results)) {
                    let counter = 0;
                    let maxIterations = 4;

                    const sound = new Howl({
                        src: [
                            'https://ucarecdn.com/5486f7b7-ebe9-42d3-bea2-852703d95158/newOrder11.mp3'
                        ],
                        loop: true,
                        onloaderror: (id, error) => {
                            this.$notify({
                                group: 'settings',
                                title: 'Sound cannot be played'
                            });

                            throw new Error(`onloaderror: ${error}`);
                        },
                        onplayerror: (id, error) => {
                            this.$notify({
                                group: 'settings',
                                title: 'Sound cannot be played'
                            });
                            sound.once('unlock', function () {
                                sound.play();
                            });

                            throw new Error(`onplayerror: ${error}`);
                        },
                        onend: function () {
                            counter++;
                            if (counter === maxIterations - 1) {
                                sound.loop(false);
                            }
                        }
                    });

                    sound.volume(1);
                    if (!sound.playing()) {
                        sound.play();
                    }
                }

                this.orders = data.results;
                this.ordersLength = data.meta?.totalCount || 0;
                this.totalPages = data.meta?.pageCount || 1;

                this.$modal.hide('api-error-modal');
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
                this.isPolling = false;
            }
        },
        onClickOutside() {
            this.showVenueFilter = false;
        },
        setVenue(val) {
            localStorage.setItem('storekit.live-order-venue-filter', val);
            this.venueFilter = val;
            this.tableFilter = null;

            this.showVenueFilter = false;
            this.fetchOrders();
        },
        setTable(val) {
            this.tableFilter = val;
            this.fetchOrders();
        },
        toggleCompleted(value) {
            this.showCompleted = !!value;
            this.fetchOrders(false, true);
        },
        toggleFailed(value) {
            this.showFailed = !!value;
            this.showCompleted = !value;
            this.fetchOrders(false, true);
        },
        async toggleFullScreen() {
            await fullscreen.toggle(
                this.$el.querySelector('.fullscreen-container'),
                {
                    teleport: false
                    // callback: isFullscreen => {
                    //     this.fullscreen = isFullscreen;
                    // }
                }
            );
            this.fullscreen = fullscreen.isFullscreen;
        },
        newerOrderExist(orders) {
            if (!orders || orders.length === 0) {
                return false;
            }

            const currentLastOrderDate = new Date(this.lastOrderDate);
            const currentLastPreOrderDate = new Date(this.lastPreOrderDate);

            let newLastOrderDate = currentLastOrderDate;
            let newLastPreOrderDate = currentLastPreOrderDate;

            for (const order of orders) {
                const orderDate = new Date(order.created_at);
                const preOrderDate = order.preOrderDateTime
                    ? new Date(order.preOrderDateTime)
                    : null;

                if (orderDate > newLastOrderDate) {
                    newLastOrderDate = orderDate;
                    break;
                }

                if (preOrderDate && preOrderDate > newLastPreOrderDate) {
                    newLastPreOrderDate = preOrderDate;
                    break;
                }
            }

            const updatedOrderDate = newLastOrderDate > currentLastOrderDate;
            const updatedPreOrderDate =
                newLastPreOrderDate > currentLastPreOrderDate;

            if (updatedOrderDate) {
                this.lastOrderDate = newLastOrderDate;
            }

            if (updatedPreOrderDate) {
                this.lastPreOrderDate = newLastPreOrderDate;
            }

            return updatedOrderDate || updatedPreOrderDate;
        }
    }
};
</script>

<style>
.orders-list {
    max-width: 18rem;
}

@media only screen and (max-width: 768px) {
    .orders-list {
        max-width: 6rem;
    }
}

.animate {
    animation: pulse-green 1s infinite;
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
}
</style>
